import { Subject } from 'rxjs';

const subject = new Subject();
export const messageService = {
    sendMessage: message => subject.next(message),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable()
};

const call = new Subject();
export const callService = {
    init: param => call.next(param),
    status: () => call.asObservable()
};

const callDriver = new Subject();
export const callDriverService = {
    init: param => call.next(param),
    status: () => call.asObservable()
};