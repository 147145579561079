import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'
import Moment from "react-moment";
import LS_SERVICE from "../../utils/localStorage";
import axios from "axios";
import { HOMECARE_SAVE_ALERT, HOMECARE_CONSULT_ALERT } from "../../utils/constant";
import ToastComponent from "../toast/toast";

class HcSubHeaderComponent extends Component {

  state = {
    show: false,
    is_doctor: LS_SERVICE.get('is_doctor'),
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
    cb: "",
    dummy: false,
    data: null
  }

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      data: props.data !== "" && props.data !== null ? props.data : props.history.location.state.subHeaderData !== undefined ? props.history.location.state.subHeaderData : "",
      booking_id: props.data !== "" && props.data !== null ? props.data.booking_id : props.history.location.state.subHeaderData !== undefined ? props.history.location.state.subHeaderData.booking_id : ""
    })
  }

  UNSAFE_componentWillMount() {
    this.setState({
      data: this.props.data !== "" && this.props.data !== null ? this.props.data : this.props.history.location.state.subHeaderData !== undefined ? this.props.history.location.state.subHeaderData : "",
      booking_id: this.props.data !== "" && this.props.data !== null ? this.props.data.booking_id : this.props.history.location.state.subHeaderData !== undefined ? this.props.history.location.state.subHeaderData.booking_id : ""
    })
  }

  handleAlertDataLoad = (alertClicked = false) => {

    const { is_doctor, booking_id } = this.state;

    axios
      .get(HOMECARE_CONSULT_ALERT({ is_doctor, booking_id }))
      .then((Response) => {
        const data = Response.data;
        const { alert } = this.state.data;
        Object.assign(alert, data);
        this.setState({ alert }, () => { if (alertClicked) this.setState({ show: !this.state.show }) });
        // setDummy(!dummy);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handlePatientAlertPopup = () => {
    const { show } = this.state;
    if (!show)
      this.handleAlertDataLoad(true);
    else
      this.setState({ show: !this.state.show })
  }

  resetButtonStates = (data) => {
    data.is_AllergyButtonActive = false;
    data.is_AlertButtonActive = false;
    data.is_CurrentButtonActive = false;
    data.is_ExisitingButtonActive = false;
    data.is_ProgressButtonActive = false;
    data.is_ECG = false;
    return data;
  }

  handleWorkListNav = (e) => {
    e.currentTarget.classList.add("active");
    this.props.history.push("/patient-list");
  };

  handleAllergiesBtn = (e, button) => {

    const { data } = this.state;
    data.is_BackPatientButtonVisible = true;
    this.resetButtonStates(data);
    data[button] = true;
    this.setState({
      booking_id: data.booking_id,
      data
    }, () => {
      this.handleAlertDataLoad();
      this.props.history.push({
        pathname: "/allergies",
        state: {
          booking_id: this.state.data.booking_id,
          subHeaderData: this.state.data
          // active_route: button
        }
      });
    }
    );
  }

  handleEcgBtn = (e) => {
    const { data } = this.state
    data.is_BackPatientButtonVisible = true;
    this.resetButtonStates(data);
    data.is_ECG = true;
    this.setState({
      booking_id: data.booking_id,
      data
    }, () => {
      this.handleAlertDataLoad();
      this.props.history.push({
        pathname: "/ecg",
        state: {
          booking_id: this.state.data.booking_id,
          subHeaderData: this.state.data,
          kardia_mrn : data.booking_id
        }
      });
    })

  }

  progressButton = (e) => {
    const { data } = this.state;
    data.is_BackPatientButtonVisible = true;
    this.resetButtonStates(data);
    data.is_ProgressButtonActive = true;
    this.setState({
      booking_id: data.booking_id,
      data
    }, () => {
      this.handleAlertDataLoad();
      this.props.history.push({
        pathname: "/progress-notes",
        state: {
          booking_id: this.state.data.booking_id,
          subHeaderData: this.state.data
        }
      });
    })
  }

  openPreviousPrescription = (e) => {
    const { data } = this.state;
    this.props.history.push({
      pathname: "/past-visit",
      state: {
        patient_id: e.target.dataset.patientid,
        booking_id: e.target.dataset.bookingid,
        pageTitle: data.pageTitle,
        subPageTitle: data.subPageTitle,
        subPageTitle2: data.subPageTitle2,
        is_homecare: data.is_homecare
      },
    });
  };

  backButton = e => {
    this.props.history.push({
      pathname: "/hc-consult",
      state: {
        patient_id: e.target.dataset.patientid,
        booking_id: e.target.dataset.bookingid,
        is_homecare: 1
      },
    });
  }

  resetToastState = (cb = "") => {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    }, () => { if (cb !== "") cb() })
  };

  handleAlertInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleAlertSubmit = (e, button) => {

    e.preventDefault();
    const target = e.target;
    const notes_value = target.dataset.name;
    const { is_doctor } = this.state;

    if (document.querySelector(`[name="${notes_value}"]`).value === "") {
      this.resetToastState(() => this.setState({
        toastSuccessClass: "bg-danger",
        toast: true,
        toastmsg: 'Alert can\'t be blank'
      }))
      return;
    }

    const PARAMS = {
      clear_alert: button,
      alert_id: target.dataset.alertid,
      booking_id: target.dataset.bookingid,
      notes: document.querySelector(`[name="${notes_value}"]`).value
    }

    axios.post(HOMECARE_SAVE_ALERT({ is_doctor }), PARAMS)
      .then((Response) => {

        let { data } = this.state;

        data.alert.notes = [Response.data, ...data.alert.notes];

        // if (button === 1) {
        //   data.alert.alerts.splice(target.dataset.index, 1)
        // } else {
        //   document.querySelector(`[name="${notes_value}"]`).value = ""
        // }

        this.setState({
          data
        }, () => this.handlePatientAlertPopup())

        // Object.assign(alert, alert);
        // this.setState({ dummy: !state.dummy })
      })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.errors.length > 0) {
          this.resetToastState(() => this.setState({
            toastSuccessClass: "bg-danger",
            toast: true,
            toastmsg: error.response.data.errors[0].message
          }))
        }
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  render() {

    const { data } = this.state;

    let subHeader;

    subHeader = (
      <Container fluid className="container_fluid_custom">
        {/* <Row className="align-items-center" className={!data.dataLoaded ? "dummy-shimmer" : ""}> */}
        <Row className="align-items-center flex-nowrap">
          <Col className="pr-0">
            <div className="hcsubhead-titlewrapper d-flex align-items-center justify-content-start">
              <div className="hcsubhead-common d-flex flex-column doc-sd-sh pr-3">
                <p><span className="font-weight-bold">{data.name}</span> {data.dob}, {data.gender}</p>
                <p>{data.uhid}</p>
              </div>
              <div className="hcsubhead-common d-flex flex-column doc-sd-sh pl-4">
                <p>{data.dataLoaded ? data.package_data.duration > 1 ? 'Days' : 'Day' : ""}</p>
                <p><span className="font-weight-bold text-white">{data.dataLoaded ? data.package_data.days + '/' + data.package_data.duration : ""}</span></p>
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-end pl-0">
            <div className="h4 m-0 d-flex  justify-content-end">

              <button
                className={`btn btn-alert px-3 mr-2 ${data.dataLoaded ? (data.alert.status ? "active" : "") : ""}`}
                // data-bookingid={data.dataLoaded ? data.alert.booking_id : ""}
                onClick={this.handlePatientAlertPopup}
              >
                Alert
            </button>
              <button
                className={`btn btn-allergies px-2 mr-2 ${data.is_ProgressButtonActive ? "active" : ""}`}
                data-bookingid={data.booking_id}
                onClick={this.progressButton}
              >
                Progress Notes
            </button>
              <button
                className={`btn btn-allergies px-2 mr-2 ${data.is_AllergyButtonActive ? "active" : ""}`}
                data-bookingid={data.booking_id}
                onClick={(e) => this.handleAllergiesBtn(e, 'is_AllergyButtonActive')}
              >
                Allergies
            </button>
              <button
                className={`btn btn-allergies px-2 mr-2 ${data.is_ExisitingButtonActive ? "active" : ""}`}
                data-bookingid={data.booking_id}
                onClick={(e) => this.handleAllergiesBtn(e, 'is_ExisitingButtonActive')}
              >
                Existing Conditions
            </button>
              <button
                className={`btn btn-allergies px-2 mr-2 ${data.is_CurrentButtonActive ? "active" : ""}`}
                data-bookingid={data.booking_id}
                onClick={(e) => this.handleAllergiesBtn(e, 'is_CurrentButtonActive')}
              >
                Current Meds
            </button>
              {/* <button
                className={`btn btn-allergies px-2 mr-2 ${data.is_ECG ? "active" : ""}`}
                data-bookingid={data.booking_id}
                onClick={this.handleEcgBtn}
              >
                ECG
            </button> */}
              <button
                className="btn btn-allergies px-2 mr-2"
                data-patientid={data.patient_id}
                data-bookingid={data.booking_id}
                onClick={this.openPreviousPrescription}
              >
                Past VC Prescriptions
            </button>
              {data.is_BackPatientButtonVisible ? (<button
                className="btn btn btn-outline-danger btn-worklist px-2  mr-2"
                data-patientid={data.patient_id}
                data-bookingid={data.booking_id}
                onClick={this.backButton}
              >
                BACK TO PATIENT
              </button>) : ""}
              <button
                className="btn btn-outline-danger btn-worklist"
                style={{
                  backgroundColor: "white",
                  color: "#d24350",
                  fontWeight: 500,
                  borderRadius: "5px",
                }}
                onClick={this.handleWorkListNav}
              >
                WORKLIST
            </button>
            </div>
          </Col>
        </Row>

        <Modal
          show={this.state.show}
          onHide={this.handlePatientAlertPopup}
          className="alert-modalbox"
        >
          <Modal.Header className="alert-modal-pop pt-3" closeButton>
            <Modal.Title className="h5">Patient Alerts</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalbody-alert">
            <Container>
              <Row className="modal-subheader radius">
                <Col xs={6} md={9}>
                  <p className="pb-0 m-0">
                    <span className="font-weight-bold">{data.name} </span> {data.dob},{data.gender} | {data.uhid}
                  </p>
                </Col>
                {/* <Col xs={6} md={3} className="text-right">
                <Button variant="btn btn-alert-clear "
                >Clear Alert</Button>
              </Col> */}
              </Row>
              {data.dataLoaded && data.alert.alerts.length > 0 ? data.alert.alerts.map((e, i) => (
                <span key={i}>
                  <Row className="my-2 mt-2">
                    <Col xs={12} md={12}>
                      <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm" className="theme-text font-weight-normal">
                        {e.alert_date + e.alert_time}
                      </Moment>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xs={9} md={9} className="pr-0">
                      <div className="patient-textwrapper border radius">
                        <textarea
                          tabIndex="1"
                          className="text-feild1 patient-vitaltextfield p-1"
                          rows="2"
                          cols="10"
                          placeholder="Type here"
                          name={`alert_data${i}`}
                          onChange={this.handleAlertInputChange}
                        ></textarea>
                      </div>
                    </Col>
                    <Col xs={3} md={3} className="text-center p-0 mt-2">
                      {/* <button className="btn btn-outline-secondary mr-2" data-name={`alert_data${i}`} data-index={i}
                        data-alertid={e.alert_id} data-bookingid={data.booking_id}
                        onClick={(e) => this.handleAlertSubmit(e, 0)}
                      >
                        SAVE
                    </button> */}
                      <button className="btn btn-outline-danger" data-name={`alert_data${i}`} data-index={i}
                        data-alertid={e.alert_id} data-bookingid={data.booking_id}
                        onClick={(e) => this.handleAlertSubmit(e, 1)}
                      >
                        SAVE &amp; CLEAR ALERT
                    </button>
                    </Col>
                  </Row>
                </span>
              )) : ""}

              {data.dataLoaded && data.alert.notes.length > 0 ? data.alert.notes.map((e, i) => (
                <span key={i}>
                  <Row className="my-2">
                    <Col md={3}>
                      <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm" className="theme-text font-weight-normal">
                        {e.alert_date + e.alert_time}
                      </Moment>
                    </Col>
                    <Col md={6} className="text-right p-0 ml-2">
                      <Row>
                        <Col>
                          <b className="text-grey">
                            {e.created_by}
                            <span>
                              <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm" className="ml-2 font-weight-normal m-0 pr-2">
                                {e.created_at.split(" ")[0] + e.created_at.split(" ")[1]}
                              </Moment>
                            </span>
                          </b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xs={6} md={9} className="pr-0">
                      <div className="patient-textwrapper border radius">
                        <p
                          className="text-feild1 patient-vitaltextfield p-3 mb-0"
                        >
                          {e.notes}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </span>
              )) : ""}

              {data.dataLoaded && data.alert.notes.length === 0 && data.alert.alerts.length === 0 ? (<p className="mt-2">No Alerts</p>) : ""}
            </Container>
          </Modal.Body>
        </Modal>
      </Container>
    )

    return (
      <>
        <section style={{ background: "#5c5c5c" }} className="section-subheader subheader-homecare">
          {subHeader}
        </section>

        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />
      </>
    );
  }
}

export default withRouter(HcSubHeaderComponent);
