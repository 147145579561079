import React, { Component } from "react";
import HeaderComponent from "../../components/header/headerComponent";
import axios from "axios";
import LS_SERVICE from "../../utils/localStorage";
import { PROGRESS_NOTES_HOMECARE, LOADER_RED, ACCORDION_TITLE } from "../../utils/constant";
import HcSubHeaderComponent from "../sub-header-hc/hcsubHeaderComponent";
import { Card, Row, Col, Accordion } from "react-bootstrap";
import Moment from "react-moment";
import Collapse_open from '../../assets/images/collapse-close.svg';
import collapse_close from '../../assets/images/collapse-minus.svg'



class ProgressNotes extends Component {
  state = {
    tab: "doctor",
    notesDataProcessing: true,
    notesDataLoaded: false,
    notesData: [],
    is_doctor: false,
    booking_id: "",
    activeTab: "",
    subHeaderData: null,
    accordion_title: [],
    accordion_title_loaded: false,
    docNotes: [],
    nurseNotes: [],
    collapsetoggleImg: 0
  }

  constructor(props) {
    super(props);
    if (props.location.state === undefined) {
      props.history.push("/patient-list");
      return;
    }
  }

  UNSAFE_componentWillMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");

    let { activeTab, is_doctor } = this.state;

    const { state } = this.props.location;

    is_doctor = LS_SERVICE.get('is_doctor');
    activeTab = state.active_tab !== "" && state.active_tab !== undefined ? state.active_tab : is_doctor ? "doctor" : "nurse";

    this.setState({
      booking_id: state.booking_id,
      is_doctor,
      activeTab,
      subHeaderData: state.subHeaderData
    })
  }

  componentDidMount() {
    this.getAccordionData();
  }

  getAccordionData = _ => {

    const { booking_id, is_doctor } = this.state
    const hospital_id = LS_SERVICE.get('active_hospital').id
    axios.get(ACCORDION_TITLE({ booking_id, is_doctor, hospital_id }))
      .then((res) => {
        this.setState({
          accordion_title: res.data.patient_package,
          accordion_title_loaded: true
        }, () => this.getNotesData(this.state.accordion_title[0].bookig_id))
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      })
  }

  handleCollapseImg = (id, i) => {
    if (i === this.state.collapsetoggleImg) {
      this.setState({
        collapsetoggleImg: -1,
        docNotes: [],
        nurseNotes: [],
        notesDataLoaded: false,
      })
    } else {
      this.setState({
        collapsetoggleImg: i,
        docNotes: [],
        nurseNotes: [],
        notesDataLoaded: false
      })
    }
    this.getNotesData(id)
  }

  getNotesData = id => {

    const { is_doctor } = this.state;

    axios.get(PROGRESS_NOTES_HOMECARE({ is_doctor, id }))
      .then(response => {
        let data = response.data.notes[0];


        this.setState({
          docNotes: data.doctorprogressnotes !== null ? data.doctorprogressnotes : [],
          nurseNotes: data.nurseprogressnotes !== null ? data.nurseprogressnotes : [],
          notesDataLoaded: true,
          notesDataProcessing: false
        })

      }).catch(error => {
        this.setState({
          patientExistLoaded: true
        })
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      })
  }

  handleAllergiesBtn = () => {
    this.props.history.push("/allergies")
  }

  handleTabActiveClick = (e, activeTab) => {

    e.preventDefault();

    if (this.state.activeTab === activeTab) return;

    this.setState({
      activeTab: activeTab,
      notesData: [],
      notesDataLoaded: false,
      notesDataProcessing: true
    }, () => this.getNotesData())
  }

  render() {

    const { accordion_title, docNotes, nurseNotes, accordion_title_loaded, collapsetoggleImg } = this.state;

    return (
      <>
        <HeaderComponent />
        <HcSubHeaderComponent data={this.state.subHeaderData} />
        <section className="progress-notes-wrapper">
          <div className="container-fluid custom-container">
            <div className="progress-notes-container pt-3">
              <Accordion defaultActiveKey="0" className="progressnotes_accordion">
                {accordion_title.length > 0 ? (
                  accordion_title.map((ele, i) => (
                    <Card key={i} className={`${collapsetoggleImg === i ? "" : "mb-3 accordion-border"}`}>
                      <Accordion.Toggle as={Card.Header} eventKey={`${i}`} className="d-flex justify-content-between"
                        onClick={() => this.handleCollapseImg(ele.bookig_id, i)}>
                        <div>
                          {ele.name}&nbsp;({ele.start_date !== "" ? (
                            <Moment format="D MMM YYYY" parse="YYYY-MM-DD">
                              {ele.start_date}
                            </Moment>
                          ) : ("/")} - {ele.end_date !== "" ? (
                            <Moment format="D MMM YYYY" parse="YYYY-MM-DD">
                              {ele.end_date}
                            </Moment>
                          ) : ("/")})
                        </div>
                        <img className="collapse-img" src={collapsetoggleImg === i ? collapse_close : Collapse_open} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`${i}`}>
                        <Card.Body className="p-0">
                          <div className="row">
                            <div className="col-6 pr-0">
                              <div className="doc-content p-4 mt-1">
                                <h5 className="mb-4">Progress Notes : Doctor</h5>

                                {docNotes.length > 0 ? docNotes.map((e, i) => (
                                  <div className="text-notes" key={i}>
                                    <div className="msg-notes">
                                      <p className="text-dark font-weight-bold">{e.notes_by_name}</p>
                                      <p>
                                        <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                          {e.date.split("T")[0] + e.date.split("T")[1]}
                                        </Moment>
                                      </p>
                                    </div>
                                    <pre>{e.notes}</pre>
                                  </div>
                                )) : (docNotes.length === 0 && !this.state.notesDataLoaded ?
                                  (
                                    <div
                                      className="col d-flex justify-content-center align-self-center mb-5"
                                      style={{
                                        visibility: `${!this.state.notesDataLoaded ? "visible" : "hidden"
                                          }`,
                                      }}
                                    >
                                      {LOADER_RED}
                                    </div>
                                  ) : (
                                    <Card className="col mb-3 mx-auto">
                                      <Card.Body className="d-flex justify-content-center">
                                        <Row>
                                          <Col>
                                            <Card.Title
                                              className="text-center no-appointement m-0"
                                            >
                                              {`No Doctor notes available!`}
                                            </Card.Title>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  ))}

                              </div>
                            </div>
                            <div className="col-6 pl-0">
                              <div className="nurse-content p-4 mt-1">
                                <h5 className="mb-4">Progress Notes : Nurse</h5>
                                {nurseNotes.length > 0 ? nurseNotes.map((e, i) => (
                                  <div className="text-notes" key={i}>
                                    <div className="msg-notes">
                                      <p className="text-dark font-weight-bold">{e.notes_by_name}</p>
                                      <p>
                                        <Moment format="D MMM Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                          {e.date.split("T")[0] + e.date.split("T")[1]}
                                        </Moment>
                                      </p>
                                    </div>
                                    <pre>{e.notes}</pre>
                                  </div>
                                )) : (nurseNotes.length === 0 && !this.state.notesDataLoaded ?
                                  (
                                    <div
                                      className="col d-flex justify-content-center align-self-center mb-5"
                                      style={{
                                        visibility: `${!this.state.notesDataLoaded ? "visible" : "hidden"
                                          }`,
                                      }}
                                    >
                                      {LOADER_RED}
                                    </div>
                                  ) : (
                                    <Card className="col mb-3 mx-auto">
                                      <Card.Body className="d-flex justify-content-center">
                                        <Row>
                                          <Col>
                                            <Card.Title
                                              className="text-center no-appointement m-0"
                                            >
                                              {`No Nurse notes available!`}
                                            </Card.Title>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                ) : (accordion_title.length === 0 && !accordion_title_loaded ?
                  (
                    <div
                      className="col d-flex justify-content-center align-self-center mb-5"
                      style={{
                        visibility: `${!accordion_title_loaded ? "visible" : "hidden"
                          }`,
                      }}
                    >
                      {LOADER_RED}
                    </div>
                  ) : (
                    <Card className="col mb-3 mx-auto">
                      <Card.Body className="d-flex justify-content-center">
                        <Row>
                          <Col>
                            <Card.Title
                              className="text-center no-appointement m-0"
                            >
                              {`No Data available!`}
                            </Card.Title>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
              </Accordion>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default ProgressNotes;


