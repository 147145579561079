/**
 *  Start Consult Container.
 *
 *  @author Arifa Arifin Ahmed <arifa@innocirc.com>
 *  @version 1.0.0 (24-May-2019)
 *  @copyright InnoCirc Ventures Pvt. Ltd.
 *
 *  @description
 *  Start Consult Conatiner for VC Desk App with Routing.
 *
 */

import React from "react";
import VideoIcon from "../../assets/images/electronics_red.svg";
import VIconWhite from "../../assets/images/electronics_white.svg";
import AudioIcon from "../../assets/images/phone_consult_gray.svg"
import AudioIconWhite from "../../assets/images/phone_consult_white.svg";
import "../../assets/styles/style.css";
import jpg from "../../assets/images/jpeg.png";
import pdf from "../../assets/images/pdf.png";
import rotateLeft from "../../assets/images/rotate-left.png";
import rotateRight from "../../assets/images/rotate-right.png";
import prevImgIcon from "../../assets/images/previous-icon.svg";
import nextImgIcon from "../../assets/images/next-icon.svg";
import mic_icon from "../../assets/images/mic.png";
import $ from 'jquery';
import Popup from "reactjs-popup";
import HeaderComponent from "../../components/header/headerComponent";
import SubHeaderComponent from "../../components/sub-header/subHeaderComponent";
import VideoConferencing from "../../components/video-conferencing/VideoConferencing";
import QbVideoConferencing from "../../components/video-conferencing/QbVideoConferencing";
import axios from "axios";
import Moment from "react-moment";
import {
  APPOINTMENT_DETAILS,
  COMPLETE_APPOINTMENT,
  SEPARATEUPLOADEDDOCS,
  GETGENDER,
  GETDOB,
  API_BASE_URL2,
  API_BASE_URL3,
  LOADER_RED,
  EXOTEL_URL,
  LOADER,
  LOADER_BLACK,
  BOOKINGTIME24TO12,
  HANDLEIMAGEROTATE,
  IMAGEPREVNEXTRESET,
  GETURLEXTENSION,
  CALL_RATING,
  COPY_PRESCRIPTION,
  STT_ANALYTICS_API,
  ZOOM_IN
} from "../../utils/constant";
import ToastComponent from "../toast/toast";
import LS_SERVICE from "../../utils/localStorage";
import { withRouter } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Button, Input } from "reactstrap";
import QbHelpers from "../../helpers/QbHelpers";
import { startStreaming, stopStreaming } from "../../utils/audioUtils";
import { getTranscriptFromJSON, clientSocketDisconnect } from "../../utils/socketAapi";
import BeautyStars from 'beauty-stars';
import Tick from "../../assets/images/ic-tick.svg";

import { messageService, callService } from '../../utils/data-sharing';
import Bandwidth from '../../components/bandwidth/bandwidth';
import ImageMaginify from "../../utils/imageMaginify";

let ENDCALL = "";
let PastPrescriptionInfinitePagination = "";
let medicine_data_set_flag = 0;
let diagnosis_data_set_flag = 0;
let updateprescription_data_set_flag = 0;
let AUDIOCONTEXT = "";
let localStorageDiagnosisNote = "";
let localStorageMedicinesTests = "";
let localStorageUpdatePresctiptions = "";
let cpInfinitePagination = ""; //cp - (copyPrescription) in whole component wherever u find, it belongs to copyPrescription
let SysCount = 1;
let MedCount = 1;
let onchangeSysData = ""
let onchangeMedData = ""

class DocComp extends React.Component {
  state = {
    callPatientBtn: true, // true means call btn disabled
    callNotificationMsg: "",
    patient: {
      name: '',
      number: null,
      id: null,
      dob: '',
      uhid: null,
      gender: '',
      booking_time: null,
    },
    appointment_status: this.props.location.state !== undefined ? this.props.location.state.booking_status_code : this.props.history.push('/patient-list'),
    appointment_details: [],
    consult_type: 1,
    uploads: [],
    past_prescriptions: [],
    past_prescription_page: 1,
    total_prescription_pages: 1,
    noMorePastPrescriptionData: false,
    isPastPrescriptionRequestProcessing: false,
    pp_dataLoaded: false,
    diagnosis_notes: "",
    medicines_tests: "",
    update_prescription_text: "",
    modal: false,
    dataFile: "",
    modalClass: "modal-imgdocs",
    documentIndex: 0,
    documentCount: 0,
    documentType: "",
    allDocuments: [],
    canEndCall: false,
    hungupExistingCall: false,
    completeAppointmentBtnValid: true,
    updatePrescriptionBtnValid: true,
    openhandleCompleteAppointmentButtonPop: false,
    openhandleCompleteAppointmentButtonPopLoader: false,
    openAudioCallPop: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
    videoEndCall: true,
    handleCallFeedbackPopup: false,
    doctor_rating: 5,
    doctor_remarks: "",
    audioMute: false,
    started: false,
    startedDianosis: false,
    startedMedicine: false,
    reset: 0,
    restartTime: '295000',
    counterStart: null,
    timestamp: '00:00:00',
    multiline: 'Controlled',
    outputText: '',
    newText: '',
    concatText: '',
    isFinal: false,
    isLastNotFinal: '',
    resetCount: 0,
    // transcriptList: [],
    // diagnosisNotesList: [],
    // medicinesTestsList: [],
    // diagnosisNotesListFinal: [],
    // medicinesTestsListFinal: [],
    // transcriptCounter: 0,
    // transcriptCounter1: 0,
    transcriptObject: {},
    restartCounter: 1,
    diagnosisBeforeAppendFlag: 1,
    medicneBeforeAppendFlag: 1,
    disableTextFields: false,
    diagTextActive: false,
    mediTextActive: false,
    updateTextActive: false,
    callInitialized: false,
    video_call_driver: null, // 1 for QB, 2 for Vonage
    copyPrescription_data: [],
    copyNotesDoc: "",
    copyDocumentsUploads: [],
    copySymptoms: "",
    copyMedicineAdvised: "",
    copyUpdatedNotes: "",
    copyActiveId: 0,
    copyDataLoaded: false,
    isSymptomsTextCheck: false,
    isMedicineDiagTextCheck: false,
    isConfirmSymptomsBtn: false,
    isConfirmMedsDiaBtn: false,
    handleSymptomsCheckedData: "",
    handleMediCheckedData: "",
    SymptomsIdGotChecked: 0,
    MedicinesIdGotChecked: 0,
    displaySymptomsBtn: true,
    displayMedicineBtn: true,
    copyPresc_pagination: 1,
    copyPresc_prev_pagination: 0,
    noMoreCpData: false,
    isCpReqProcessing: false,
    cpUploadPrescDate: 0,
    isSysCopiedDataEdited: false,
    isMedCopiedDataEdited: false,
  };

  constructor(props) {
    super(props);
    this.receiveInParent = this.receiveInParent.bind(this);
    this.updatePage = this.updatePage.bind(this);
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    const { booking_id } = this.props.location.state;

    axios
      .get(APPOINTMENT_DETAILS({ booking_id }))
      .then((Response) => {
        let name = `${Response.data.appointment.patient.first_name} ${Response.data.appointment.patient.last_name !== null
          ? Response.data.appointment.patient.last_name
          : ""
          }`;
        let formData = [];
        if (LS_SERVICE.has('formData')) {
          formData = LS_SERVICE.get('formData');
        }
        let diagnosis_notes = '';
        let medicines_tests = '';
        let update_prescription_text = '';
        if (formData.find(e => e.id === Response.data.appointment.id) !== undefined) {
          formData.forEach(e => {
            if (e.id === Response.data.appointment.id) {
              diagnosis_notes = e['diagnosis_notes'];
              medicines_tests = e['medicines_tests'];
              update_prescription_text = e['update_prescription_text'] !== undefined ? e['update_prescription_text'] : "";
            }
          })
        }
        this.setState({
          appointment_status: Response.data.appointment.booking_status_code,
          appointment_details: Response.data.appointment,
          consult_type: Response.data.appointment.consult_type,
          uploads: SEPARATEUPLOADEDDOCS(
            Response.data.appointment.patient_docments
          ),
          diagnosis_notes: diagnosis_notes !== '' ? diagnosis_notes : Response.data.appointment.diagnosis_notes ?? "",
          medicines_tests: medicines_tests !== '' ? medicines_tests : Response.data.appointment.medicines_tests ?? "",
          update_prescription_text: update_prescription_text !== '' ? update_prescription_text : Response.data.appointment.update_prescription ?? "",
          patient: {
            name: name,
            number: Response.data.appointment.patient.mobile_no,
            id: Response.data.appointment.patient.id,
            dob: GETDOB(Response.data.appointment.patient.dob),
            uhid: Response.data.appointment.patient.uhid,
            booking_time: Response.data.appointment.start_time,
            gender: GETGENDER(Response.data.appointment.patient.gender),
          },
          video_call_driver: Response.data.appointment.is_vc_call || null
        }, () => {
          localStorageDiagnosisNote = this.state.diagnosis_notes;
          localStorageMedicinesTests = this.state.medicines_tests;
          localStorageUpdatePresctiptions = this.state.update_prescription_text;
          this.pastPresciptions();
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });

    getTranscriptFromJSON((err, transcriptObject) => {
      if (diagnosis_data_set_flag === 1) {
        let { diagnosis_notes } = this.state;
        if (!transcriptObject.isFinal) {
          diagnosis_notes = this.sentenceCase(localStorageDiagnosisNote + " " + transcriptObject.transcript)
          this.setState({
            diagnosis_notes
          }, () => this.diagnosisNotesRef.current.scrollTop = this.diagnosisNotesRef.current.scrollHeight)
        }

        if (transcriptObject.isFinal) {
          diagnosis_notes = this.sentenceCase(localStorageDiagnosisNote + " " + transcriptObject.transcript)
          this.setState({
            diagnosis_notes,
          }, () => {
            localStorageDiagnosisNote = this.state.diagnosis_notes;
            this.diagnosisNotesRef.current.scrollTop = this.diagnosisNotesRef.current.scrollHeight;
          }
          );
        }
      }

      if (medicine_data_set_flag === 1) {
        let { medicines_tests } = this.state;
        if (!transcriptObject.isFinal) {
          medicines_tests = this.sentenceCase(localStorageMedicinesTests + " " + transcriptObject.transcript);
          this.setState({
            medicines_tests
          }, () => this.medicineNotesRef.current.scrollTop = this.medicineNotesRef.current.scrollHeight);
        }
        if (transcriptObject.isFinal) {
          medicines_tests = this.sentenceCase(localStorageMedicinesTests + " " + transcriptObject.transcript);
          this.setState({
            medicines_tests
          }, () => {
            localStorageMedicinesTests = this.state.medicines_tests;
            this.medicineNotesRef.current.scrollTop = this.medicineNotesRef.current.scrollHeight;
          });
        }
      }

      if (updateprescription_data_set_flag === 1) {
        let { update_prescription_text } = this.state;
        if (!transcriptObject.isFinal) {
          update_prescription_text = this.sentenceCase(localStorageUpdatePresctiptions + " " + transcriptObject.transcript)
          this.setState({
            update_prescription_text
          }, () => this.updatepresctiptionRef.current.scrollTop = this.updatepresctiptionRef.current.scrollHeight)
        }

        if (transcriptObject.isFinal) {
          update_prescription_text = this.sentenceCase(localStorageUpdatePresctiptions + " " + transcriptObject.transcript)
          this.setState({
            update_prescription_text,
          }, () => {
            localStorageUpdatePresctiptions = this.state.update_prescription_text;
            this.updatepresctiptionRef.current.scrollTop = this.updatepresctiptionRef.current.scrollHeight;
          }
          );
        }
      }
    });

    this.handleInputChange = this.handleInputChange.bind(this);
    this.diagnosisNotesRef = React.createRef();
    this.medicineNotesRef = React.createRef();
    this.updatepresctiptionRef = React.createRef();
  }

  updatePage() {
    console.log('OUTOUTOUT');
    this.forceUpdate();
  }

  componentDidMount() {
    this.addScrollEvent();
    const frmData = LS_SERVICE.get('formData');
    if (!frmData) {
      LS_SERVICE.set('formData', []);
    }

    this.subscription = messageService.getMessage().subscribe(message => {
      if (message && message.update) {
        window.location.reload(true);
      }
    });
  }

  componentWillUnmount() {
    this.cleanupListener();
    if (this.state.audio) {
      this.stopListening();
    }
    console.log('>>> componentWillUnmount has been called');
    this.subscription.unsubscribe();
  }

  addScrollEvent = () => {
    PastPrescriptionInfinitePagination = document.querySelector(".scroll_loader");
    if (PastPrescriptionInfinitePagination !== null)
      PastPrescriptionInfinitePagination.addEventListener("scroll", this.handleScroll, true);
  };

  cleanupListener = () => {
    PastPrescriptionInfinitePagination.removeEventListener("scroll", this.handleScroll, true);
  };

  checkScrollSpaceAvailability = () => {
    if (this.state.past_prescription_page !== 1) return;
    const lastLi = document.querySelector(
      ".scroll_loader .past-prescriptionlist  > li:last-child"
    );
    const scrollWin = document.querySelector(".scroll_loader");
    if (lastLi === null) return;
    const listLiTotalHeight = lastLi.clientHeight * document.querySelectorAll(".scroll_loader .past-prescriptionlist li").length;
    const pageOffset = scrollWin.scrollTop + scrollWin.clientHeight;
    if (scrollWin.scrollTop === 0) {
      if (pageOffset > listLiTotalHeight) {
        this.loadMore();
      }
    }
  }

  handleScroll = () => {
    const lastLi = document.querySelector(
      ".scroll_loader .past-prescriptionlist  > li:last-child"
    );
    const scrollWin = document.querySelector(".scroll_loader");
    if (lastLi === null) return;
    var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
    let pageOffset = scrollWin.scrollTop + scrollWin.scrollHeight;
    if (pageOffset > lastLiOffset) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { noMorePastPrescriptionData, isPastPrescriptionRequestProcessing } = this.state;

    if (noMorePastPrescriptionData) return;
    if (isPastPrescriptionRequestProcessing === true) return;

    this.setState({
      isPastPrescriptionRequestProcessing: true,
    });

    this.setState(
      (prevState) => ({
        past_prescription_page: prevState.past_prescription_page + 1
      }),
      () => {
        this.pastPresciptions()
      }
    );
  };

  pastPresciptions = () => {

    const { past_prescription_page, patient } = this.state;

    axios
      .get(
        API_BASE_URL3 +
        "patient-past-prescription?patient_id=" +
        patient.id +
        "&page=" + past_prescription_page
      )
      .then((response) => {
        const dataNew = response.data.reports;
        const tPages = response.data.pagination.total_pages;
        const noData = response.data.pagination.links.next === "" ? true : false;
        const {
          past_prescriptions
        } = this.state;

        this.setState({
          past_prescriptions: [...past_prescriptions, ...dataNew],
          pp_dataLoaded: true,
          total_prescription_pages: tPages,
          isPastPrescriptionRequestProcessing: false,
          noMorePastPrescriptionData: noData,
        }, () => this.checkScrollSpaceAvailability());
      })
      .catch((error) => {
        this.setState({
          pp_dataLoaded: true,
        });
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  documentToggle = (e, files, isDoc = false) => {
    if (files !== undefined) {
      if (isDoc) {
        this.setState({
          modalClass: "modal-imgdocs modal-docs",
          documentType: 'application/pdf',
        });
      } else {
        this.setState({
          modalClass: "modal-imgdocs",
          documentType: '',
        });
      }
      if (e !== null) {
        e.preventDefault();
        this.setState({
          documentIndex: $(e.currentTarget).index(),
          documentCount: e.currentTarget.parentElement.childElementCount
        }, () => {
          this.setState({
            showArrow: this.state.documentCount > 1 ? true : false,
          })
        })

        this.setState({
          allDocuments: files,
          dataFile: files[$(e.currentTarget).index()],
        });
      } else {
        this.setState({
          dataFile: files,
        })
      }
    }

    this.setState({
      modal: !this.state.modal,
    })
  };

  callUser() {
    callService.init({ start: true, is_homecarecall: false });
    // this.handleCallRatings(null, { status: 0 });
    // this.handleCallRatings(null, { status: 0, callStatus: () => callService.init({ start: true }) });
  }

  endCall() {
    callService.init({ end: true, is_homecarecall: false });
    // this.handleCallRatings(null, { status: 1 });
  }

  receiveInParent(callInfo) {
    if (typeof callInfo !== "undefined") {
      this.setState(
        {
          callPatientBtn: callInfo.callPatientBtn,
          callNotificationMsg: callInfo.callNotificationMsg,
          canEndCall: callInfo.canEndCall,
          hungupExistingCall: callInfo.hungupExistingCall || false,
        },
        () => {
          this.checkAppointmentForm();
        }
      );
    }
  }

  checkAppointmentForm() {
    if (this.state.medicines_tests.length > 0 || this.state.diagnosis_notes.length > 0) {
      this.setState({
        completeAppointmentBtnValid: false,
      });
    } else {
      this.setState({
        completeAppointmentBtnValid: true,
      });
    }
  }

  checkUpdatePrescriptionForm = () => {
    this.setState({
      updatePrescriptionBtnValid: this.state.update_prescription_text.length === 0
    })
  }

  handleInputChange(event) {

    const target = event.target;
    // debugger
    // const { handleSymptomsCheckedData, handleMediCheckedData } = this.state

    let frmData = {
      id: this.state.appointment_details.id,
      diagnosis_notes: this.state.diagnosis_notes,
      medicines_tests: this.state.medicines_tests,
      update_prescription_text: this.state.update_prescription_text
    }

    // if (target.name === "diagnosis_notes" && !handleSymptomsCheckedData.length > 0) {
    //   onchangeSysData = target.value;
    // }
    // else if (target.name === "medicines_tests" && !handleMediCheckedData.length > 0) {
    //   onchangeMedData = target.value;
    // }
    // if (target.name === "diagnosis_notes" && SysCount === 1 && !handleSymptomsCheckedData.length > 0) {
    //   this.setState({
    //     isSysCopiedDataEdited: true
    //   })
    //   SysCount++;
    // }
    // else if (target.name === "medicines_tests" && MedCount === 1 && !handleMediCheckedData.length > 0) {
    //   this.setState({
    //     isMedCopiedDataEdited: true
    //   })
    //   MedCount++;
    // }
    this.setState({ [target.name]: target.value },
      () => {
        frmData.diagnosis_notes = this.state.diagnosis_notes;
        frmData.medicines_tests = this.state.medicines_tests;
        frmData.update_prescription_text = this.state.update_prescription_text;
        localStorageDiagnosisNote = frmData.diagnosis_notes;
        localStorageMedicinesTests = frmData.medicines_tests;
        localStorageUpdatePresctiptions = frmData.update_prescription_text;
        this.formDataEntry(frmData);
      });
  }

  formDataEntry(data) {

    const { id, diagnosis_notes, medicines_tests } = data;

    let formData = [];
    if (LS_SERVICE.has('formData')) {
      formData = LS_SERVICE.get('formData');
    } else {
      formData = [{
        id,
        diagnosis_notes,
        medicines_tests
      }];
      LS_SERVICE.set('formData', formData)
    }

    if (formData.find((e) => e.id === this.state.appointment_details.id) !== undefined) {
      formData.forEach(e => {
        if (e.id === this.state.appointment_details.id) {
          e['diagnosis_notes'] = data.diagnosis_notes;
          e['medicines_tests'] = data.medicines_tests;
        }
      })
    } else {
      formData = [...formData, { id, diagnosis_notes, medicines_tests }]
    }
    LS_SERVICE.set('formData', formData);
    this.checkAppointmentForm();
    this.checkUpdatePrescriptionForm();
  }

  completeAppointment(e, booking_id) {
    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    if (ENDCALL !== "") {
      clearInterval(ENDCALL);
      ENDCALL = "";
    }

    let formData = [];
    if (LS_SERVICE.has('formData')) {
      formData = LS_SERVICE.get('formData');
    }

    if (formData.find(e => e.id === booking_id) !== undefined) {
      formData = formData.filter((e) => e.id !== booking_id);
      LS_SERVICE.set('formData', formData);
    }

    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    let PARAMS = {};

    if (this.state.appointment_details.prescription_bool) {
      PARAMS = {
        booking_id: booking_id,
        update_prescription: this.state.update_prescription_text
      }
    } else {
      PARAMS = {
        booking_id: booking_id,
        diagnosis_notes: this.state.diagnosis_notes,
        medicines_tests: this.state.medicines_tests,
      }
    }

    axios
      .post(COMPLETE_APPOINTMENT, PARAMS)
      .then((Response) => {
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        this.setState({
          openhandleCompleteAppointmentButtonPop: false,
        }, () => {
          if (this.state.callInitialized) {
            this.handleBeautyPopup();
          } else {
            this.setState({
              handleCallFeedbackPopup: false,
              appointment_status: 5,
              toastSuccessClass: "bg-success",
              toast: true,
              toastmsg: "You Appointment is Completed",
            }, () => {
              if (!this.state.canEndCall) {
                this.props.history.push("/patient-lists")
              }
            })
          }
        });

      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').toggleClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });

    axios
      .post(STT_ANALYTICS_API, {
        doctor_id: LS_SERVICE.get("doctor").id,
        booking_id: this.state.appointment_details.id,
        source: 0,
        step: 1,
        field_type: 0,
        input_text: this.state.diagnosis_notes
      }, {
        headers: {
          "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE1ODAxMjA1NzJ9.Dxt82xQNynJo4BKqRPNnnG6Tvs7KsfkGLBfWMNYPmFE`,
        },
      })
      .then((response) => {
        console.log("response", response)
      })
      .catch((error) => {
        console.log("error", error)
      });

    axios
      .post(STT_ANALYTICS_API, {
        doctor_id: LS_SERVICE.get("doctor").id,
        booking_id: this.state.appointment_details.id,
        source: 0,
        step: 1,
        field_type: 1,
        input_text: this.state.medicines_tests
      }, {
        headers: {
          "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE1ODAxMjA1NzJ9.Dxt82xQNynJo4BKqRPNnnG6Tvs7KsfkGLBfWMNYPmFE`,
        },
      })
      .then((response) => {
        // console.log("response", response)
      })
      .catch((error) => {
        // console.log("error", error)
      });
  }

  endCallAndCompleteAppointment(e, booking_id) {
    $(e.target).find('span.loader-span').addClass('loader-active');
    this.endCall();
    ENDCALL = setInterval(() => {
      if (!QbHelpers.isCallActive || this.state.videoEndCall) {
        this.completeAppointment(null, booking_id);
      }
    }, 100);
  }

  openPrescriptionDoc(booking_id) {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
    axios.get(API_BASE_URL2 + "bookings/" + booking_id + "/prescriptions?mode=web")
      .then((Response) => {
        var pdfUrl = Response.data;
        this.documentToggle(null, pdfUrl, true)
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleCompleteAppointmentClick = () => {

    const { audio, isConfirmMedsDiaBtn, isConfirmSymptomsBtn } = this.state;

    if (isConfirmMedsDiaBtn || isConfirmSymptomsBtn) {
      this.resetToastState(() => this.setState({
        toastSuccessClass: "bg-danger",
        toast: true,
        toastmsg: "Kindly confirm before proceeding"
      }))
      return;
    }

    if (audio) {
      this.resetToastState(() => this.setState({
        toastSuccessClass: "bg-danger",
        toast: true,
        toastmsg: "Close Mic to Proceed"
      }))
      return;
    }

    this.setState({
      openhandleCompleteAppointmentButtonPop: true
    }, () => {
      const { video_call_driver } = this.state;

      const callActive = video_call_driver === 2 ? !window.InncVonage.data.call_started : !QbHelpers.isCallActive;

      this.setState({
        videoEndCall: callActive
      })
    })
  }

  handleCompleteAppointmentButton = (value) => {
    this.setState({
      openhandleCompleteAppointmentButtonPop: value
    })
  }

  handleAudioCallPop = (value) => {
    this.setState({
      openAudioCallPop: value
    })
  }

  handleAUdioCallApi = (e) => {
    const elem = e.target;
    $(elem).find('span.loader-span').toggleClass('loader-active');
    const { id } = this.state.appointment_details;

    const is_doctor = LS_SERVICE.get('is_doctor');

    axios.post(EXOTEL_URL({ is_doctor }), { booking_id: id })
      .then((response) => {
        $(elem).find('span.loader-span').toggleClass('loader-active');
        this.setState({
          openAudioCallPop: false
        })
      })
      .catch((error) => {
        $(elem).find('span.loader-span').toggleClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleNextPrev = (e) => {
    e.preventDefault();
    const { documentIndex, allDocuments, documentCount } = this.state;
    let docIndex = documentIndex;
    const targetName = e.currentTarget;
    if (targetName.getAttribute("name") === "image-left") {
      if (docIndex > 0) {
        this.setState({
          documentIndex: documentIndex - 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex - 1]),
          dataFile: allDocuments[docIndex - 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    } else {
      if (docIndex < (documentCount - 1)) {
        this.setState({
          documentIndex: documentIndex + 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex + 1]),
          dataFile: allDocuments[docIndex + 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    }
  };

  resetToastState = (cb = "") => {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    }, () => { if (cb !== "") cb() }
    );
  };

  handleDontRate = _ => {
    this.handleCallRatings(null, { status: 2 });
  };

  handleBeautyPopup = _ => {
    this.setState({
      handleCallFeedbackPopup: !this.state.handleCallFeedbackPopup
    });
  }

  cpScrollEvent = () => {
    cpInfinitePagination = document.querySelector(".cp_scroll_loader");
    if (cpInfinitePagination !== null) {
      cpInfinitePagination.addEventListener("scroll", this.handleCpScroll, true);
    }
  }

  checkCpScrollSpaceAvai = () => {
    if (this.state.copyPresc_pagination !== 1) return;
    const lastLi = document.querySelector(
      ".cp_scroll_loader > div:last-child"
    );
    const scrollWin = document.querySelector(".cp_scroll_loader");
    if (lastLi === null) return;
    const listLiTotalHeight = lastLi.clientHeight * document.querySelectorAll(".cp_scroll_loader > div").length;
    const pageOffset = scrollWin.scrollTop + scrollWin.clientHeight;
    if (scrollWin.scrollTop === 0) {
      if (pageOffset > listLiTotalHeight) {
        this.cpLoadMore();
      }
    }
  }

  handleCpScroll = () => {
    const lastLi = document.querySelector(
      ".cp_scroll_loader > div > div:last-child"
    );
    const scrollWin = document.querySelector(".cp_scroll_loader");
    if (lastLi === null) return;
    let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
    let pageOffset = scrollWin.scrollTop + scrollWin.clientHeight + lastLi.clientHeight;
    if (pageOffset > lastLiOffset) {
      this.cpLoadMore();
    }
  };

  cpLoadMore = () => {
    const { noMoreCpData, isCpReqProcessing } = this.state

    if (noMoreCpData) return;
    if (isCpReqProcessing === true) return;

    this.setState({
      isCpReqProcessing: true,
    });

    this.setState(
      (prevState) => ({
        copyPresc_pagination: prevState.copyPresc_pagination + 1
      }),
      () => {
        this.handleCopyPrescription()
      }
    );
  }

  handleCpReset = () => {
    this.setState({
      isSymptomsTextCheck: false,
      isMedicineDiagTextCheck: false,
      isConfirmMedsDiaBtn: false,
      isConfirmSymptomsBtn: false,
      openCopyPrescription: false,
      handleSymptomsCheckedData: "",
      handleMediCheckedData: " "
    })
  }

  // handleCpPaginationFunc = () => {
  //   const { copyPresc_pagination, copyPrescription_data } = this.state;

  //   const hospital_id = LS_SERVICE.get('hospitals')[0].id
  //   const patient_id = this.state.patient.id

  //   axios.get(COPY_PRESCRIPTION({ copyPresc_pagination, patient_id, hospital_id }))
  //     .then((res) => {
  //       const data = res.data.reports;
  //       const noData = res.data.pagination.links.next === "" ? true : false;
  //       this.setState({
  //         copyPrescription_data: [...copyPrescription_data, ...data],
  //         isCpReqProcessing: false,
  //         noMoreCpData: noData
  //       })
  //     }, () => this.handleCpScroll())
  //     .catch((error) => {
  //       this.setState({
  //         isCpReqProcessing: true,
  //       });
  //       if (error && error.response && error.response.status === 401) {
  //         LS_SERVICE.clear();
  //         this.props.history.push("/");
  //       }
  //     });

  // }

  handleCopyPrescription = () => {

    const { copyPresc_pagination, copyPrescription_data, copyPresc_prev_pagination } = this.state;

    this.setState({
      openCopyPrescription: true
    }, () => {
      setTimeout(() => {
        if (copyPresc_prev_pagination === copyPresc_pagination) {
          this.cpScrollEvent();
          return;
        }
      }, 100)
    });

    if (copyPresc_prev_pagination === copyPresc_pagination) return;

    const hospital_id = LS_SERVICE.get('active_hospital').id;
    const patient_id = this.state.patient.id;
    const doctor_id = LS_SERVICE.get("doctor").id;

    axios.get(COPY_PRESCRIPTION({ hospital_id, patient_id, copyPresc_pagination, doctor_id }))
      .then((response) => {
        const data = response.data.reports;
        const noData = response.data.pagination.links.next === "" ? true : false;

        this.setState({
          copyPrescription_data: [...copyPrescription_data, ...data],
          isCpReqProcessing: false,
          copyPresc_prev_pagination: copyPresc_pagination,
          noMoreCpData: noData,
          copyDataLoaded: true
        }, () => {
          if (this.state.copyPrescription_data.length > 0) {
            this.handleShowCopyData(this.state.copyPrescription_data[0].id);
          }
          this.checkCpScrollSpaceAvai();
          this.cpScrollEvent();
        })
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      })
  }
  // handleResetCopyPrescription=()=>{
  //   this.setState({
  //     openCopyPrescription : false,
  //     copyActiveId :0,
  //     isSymptomsTextCheck: false,
  //     isMedicineDiagTextCheck: false,
  //     isConfirmSymptomsBtn: false,
  //     isConfirmMedsDiaBtn: false,
  //     handleSymptomsCheckedData: "",
  //     handleMediCheckedData: "",
  //     SymptomsIdGotChecked: 0,
  //     MedicinesIdGotChecked: 0,
  //     displaySymptomsBtn : true,
  //     displayMedicineBtn : true
  //   })
  // }

  handleSymptomsChecked = () => {
    const { copySymptoms, SymptomsIdGotChecked, copyActiveId, diagnosis_notes } = this.state
    if (SymptomsIdGotChecked === copyActiveId) {
      this.setState(prev => ({
        isSymptomsTextCheck: !prev.isSymptomsTextCheck,
        isConfirmSymptomsBtn: prev.isConfirmSymptomsBtn,
        handleSymptomsCheckedData: diagnosis_notes,
        SymptomsIdGotChecked: 0
      }))
    } else {
      this.setState({
        isSymptomsTextCheck: true,
        isConfirmSymptomsBtn: true,
        handleSymptomsCheckedData: copySymptoms,
        SymptomsIdGotChecked: copyActiveId
      })
    }

  }

  handleMedicineChecked = () => {
    const { copyMedicineAdvised, MedicinesIdGotChecked, copyActiveId, medicines_tests } = this.state
    if (MedicinesIdGotChecked === copyActiveId) {
      this.setState(prev => ({
        isMedicineDiagTextCheck: !prev.isMedicineDiagTextCheck,
        isConfirmMedsDiaBtn: prev.isConfirmMedsDiaBtn,
        handleMediCheckedData: medicines_tests,
        MedicinesIdGotChecked: 0
      }))
    } else {
      this.setState({
        isMedicineDiagTextCheck: true,
        isConfirmMedsDiaBtn: true,
        handleMediCheckedData: copyMedicineAdvised,
        MedicinesIdGotChecked: copyActiveId
      })
    }

  }

  handleCheckboxCopy = (isConfirm = false) => {
    const { handleSymptomsCheckedData, handleMediCheckedData, diagnosis_notes, medicines_tests, misSysCopiedDataEdited, isMedCopiedDataEdited } = this.state;

    this.setState({
      diagnosis_notes: handleSymptomsCheckedData.trim() !== "" ? handleSymptomsCheckedData.trim() : diagnosis_notes,
      medicines_tests: handleMediCheckedData.trim() !== "" ? handleMediCheckedData.trim() : medicines_tests,
      // diagnosis_notes: (diagnosis_notes + ' ' + handleSymptomsCheckedData).trim(),
      // medicines_tests: (medicines_tests + ' ' + handleMediCheckedData).trim(),
      openCopyPrescription: false,
      isMedicineDiagTextCheck: false,
      isSymptomsTextCheck: false,
      // copyActiveId: 0,
      SymptomsIdGotChecked: 0,
      MedicinesIdGotChecked: 0,
      // copyPresc_pagination: 1,
      // copyPrescription_data: [],
      // copyDataLoaded: false
    })

    if (isConfirm) {
      const { diagnosis_notes, medicines_tests } = this.state;

      this.handleInputChange({ target: { name: 'diagnosis_notes', value: diagnosis_notes } });
      this.handleInputChange({ target: { name: 'medicines_tests', value: medicines_tests } });
    }
  }

  handleShowCopyData = (id) => {
    const { copyPrescription_data } = this.state
    const data = copyPrescription_data.find(e => e.id === id)
    const filterDocs = data.patient_docments.filter(ele => ele.booking_id === data.id)

    this.setState({
      copyNotesDoc: data.patient_note_doctor,
      copyDocumentsUploads: SEPARATEUPLOADEDDOCS(filterDocs),
      copyMedicineAdvised: data.medicines_tests,
      copyUpdatedNotes: data.update_prescription,
      copySymptoms: data.diagnosis_notes,
      copyActiveId: id,
      // handleSymptomsCheckedData: "",
      // handleMediCheckedData: "",
      // isConfirmMedsDiaBtn: false,
      // isConfirmSymptomsBtn: false,
      cpUploadPrescDate: data.update_prescription_date,
      isSymptomsTextCheck: false,
      isMedicineDiagTextCheck: false
    })

  }

  handleConfirmCP = (type) => {

    if (type === 0) {
      this.setState({
        isConfirmSymptomsBtn: false,
        displaySymptomsBtn: false
      }, () => {
        if (!this.state.isConfirmMedsDiaBtn && !this.state.isConfirmSymptomsBtn) {
          this.checkAppointmentForm()
        }
      })
    } else {
      this.setState({
        isConfirmMedsDiaBtn: false,
        displayMedicineBtn: false
      }, () => {
        if (!this.state.isConfirmMedsDiaBtn && !this.state.isConfirmSymptomsBtn) {
          this.checkAppointmentForm()
        }
      })
    }

    this.handleCheckboxCopy(true);
  }

  handleCallRatings = (e, data) => {

    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').addClass('loader-active');
    }

    this.resetToastState();

    const { doctor_rating, doctor_remarks } = this.state;

    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    axios
      .post(CALL_RATING, {
        booking_id: this.state.appointment_details.id,
        doctor_remarks,
        doctor_rating,
        state: data.status // 2 for feedback Submit 1 for Call end and 0 for call start
      })
      .then((Response) => {
        if (data.status === 2) {
          if (elem !== null)
            $(elem).find('span.loader-span').removeClass('loader-active');
          this.setState({
            handleCallFeedbackPopup: false,
            appointment_status: 5,
            toastSuccessClass: "bg-success",
            toast: true,
            toastmsg: "You Appointment is Completed",
          }, () => {
            if (!this.state.canEndCall) {
              this.props.history.push("/patient-lists")
            }
          })
        } else {
          this.setState({
            callInitialized: true,
            handleCallFeedbackPopup: false
          }, () => {
            // if (data.callStatus) {
            //   data.callStatus();
            // } else if (!this.state.canEndCall) {
            //   this.props.history.push("/patient-lists");
            // }
          })
        }
      })
      .catch((error) => {
        if (elem !== null)
          $(elem).find('span.loader-span').removeClass('loader-active');
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleBeautyPopupSubmit = (e) => {
    let elem = null;
    if (e !== null) {
      elem = e.target;
      $(elem).find('span.loader-span').toggleClass('loader-active');
    }

    const { doctor_rating } = this.state;

    if (doctor_rating === 0) {
      $(elem).find('span.loader-span').toggleClass('loader-active');
      $('.error-beautystars').removeClass('hidden');
      return;
    }

    this.handleCallRatings(e, { status: 2 });
  }

  setDoctorRating = (doctor_rating) => {
    this.setState({
      doctor_rating
    }, () => {
      if (this.state.doctor_rating > 0) {
        $('.error-beautystars').addClass('hidden');
      }
    })
  }

  startListening() {
    startStreaming(AUDIOCONTEXT);
    this.setState({ audio: true, isFinal: true });
  }

  dataToLocalStorage = () => {
    let formData = [];
    if (LS_SERVICE.has('formData')) {
      formData = LS_SERVICE.get('formData');
    }

    if (formData.find(e => e.id === this.state.appointment_details.id) !== undefined) {
      formData.forEach(e => {
        if (e.id === this.state.appointment_details.id) {
          e.diagnosis_notes = this.diagnosisNotesRef.current.value;
          e.medicines_tests = this.medicineNotesRef.current.value;
          e.update_prescription_text = this.updatepresctiptionRef.current !== null ? this.updatepresctiptionRef.current.value : this.state.update_prescription_text
        }
      })
    } else {
      formData = [...formData, {
        id: this.state.appointment_details.id,
        diagnosis_notes: this.diagnosisNotesRef.current.value,
        medicines_tests: this.medicineNotesRef.current.value,
        update_prescription_text: this.updatepresctiptionRef.current !== null ? this.updatepresctiptionRef.current.value : this.state.update_prescription_text
      }]
    }
    LS_SERVICE.set('formData', formData);
    this.checkAppointmentForm();
    this.checkUpdatePrescriptionForm();
  }

  stopListening() {
    clientSocketDisconnect();
    this.setState({ audio: false, started: false, startedDianosis: false, startedMedicine: false, startedUpcomingPrescription: false, disableTextFields: true });
    AUDIOCONTEXT.close();
    stopStreaming(AUDIOCONTEXT);
    this.setState({ disableTextFields: false, diagTextActive: false, mediTextActive: false, updateTextActive: false, isFinal: false });
    this.dataToLocalStorage();
  }

  toggleListen = (e) => {
    const target = e.currentTarget;
    const buttonName = target.name;
    if (!this.state.started) {
      AUDIOCONTEXT = new (window.AudioContext || window.webkitAudioContext)();
      if (buttonName === "diagnosis_notes") {
        diagnosis_data_set_flag = 1;
        medicine_data_set_flag = 0;
        updateprescription_data_set_flag = 0
        this.setState({
          started: true, startedDianosis: true, diagTextActive: true,
        });
      } else if (buttonName === "medicine_test") {
        diagnosis_data_set_flag = 0;
        medicine_data_set_flag = 1;
        updateprescription_data_set_flag = 0
        this.setState({
          started: true, startedMedicine: true, mediTextActive: true,
        })
      } else {
        diagnosis_data_set_flag = 0;
        medicine_data_set_flag = 0;
        updateprescription_data_set_flag = 1;
        this.setState({
          started: true, startedUpcomingPrescription: true, updateTextActive: true,
        })
      }
    }

    if (this.state.audio) {
      this.stopListening();

      const doctor_id = LS_SERVICE.get("doctor").id;

      const api_data = {
        doctor_id,
        booking_id: this.state.appointment_details.id,
        source: 0,
        step: 0,
        field_type: diagnosis_data_set_flag ? 0 : 1,
        input_text: diagnosis_data_set_flag ? this.state.diagnosis_notes : this.state.medicines_tests
      }

      axios
        .post(STT_ANALYTICS_API, api_data, {
          headers: {
            "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE1ODAxMjA1NzJ9.Dxt82xQNynJo4BKqRPNnnG6Tvs7KsfkGLBfWMNYPmFE`,
          },
        })
        .then((response) => {
          // console.log("response", response)
        })
        .catch((error) => {
          // console.log("error", error)
        });
    } else {
      this.startListening();

      if (this.state.diagnosis_notes.length || this.state.medicines_tests.length) {
        console.log("MADE API CALL")
        const doctor_id = LS_SERVICE.get("doctor").id;

        const api_data = {
          doctor_id,
          booking_id: this.state.appointment_details.id,
          source: 0,
          step: 1,
          field_type: diagnosis_data_set_flag ? 0 : 1,
          input_text: diagnosis_data_set_flag ? this.state.diagnosis_notes : this.state.medicines_tests
        }

        axios
          .post(STT_ANALYTICS_API, api_data, {
            headers: {
              "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE1ODAxMjA1NzJ9.Dxt82xQNynJo4BKqRPNnnG6Tvs7KsfkGLBfWMNYPmFE`,
            },
          })
          .then((response) => {
            // console.log("response", response)
          })
          .catch((error) => {
            // console.log("error", error)
          });
      }

    }
  }


  sentenceCase = (input, lowercaseBefore) => {
    input = (input === undefined || input === null) ? '' : input.toString();
    if (lowercaseBefore) { input = input.toLowerCase(); }

    let text = input.replace(/(^|\. *)([a-z])/g, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\ next line. *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\ nextline. *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\next line *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\nextline *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(^|\neckline *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(tablet)(s)?( )?/gi, "Tablet ")
      .replace(/(Tablet *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(tablet *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/(tablet *)([a-z])/gi, (match, separator, char) => separator + char.toUpperCase())
      .replace(/one/gi, "1")
      .replace(/two/gi, "2")
      .replace(/three/gi, "3")
      .replace(/four/gi, "4")
      .replace(/five/gi, "5")
      .replace(/six/gi, "6")
      .replace(/seven/gi, "7")
      .replace(/eight/gi, "8")
      .replace(/nine/gi, "9")
      .replace(/(\,*)(\.*)(\ *)(full)(\ )?(stop)(\,*)(\.*)/gi, ".")
      // .replace(/(\ *)(\.*)(\ *)((next)|(neck))(\ )?(line)(\.*)(\ *)(\.*)(\?*)/gi, ".\n")
      .replace(/(\ *)(\.*)(\ *)((next)|(neck))(\ )?(line)(\.*)(\?*)(\ *)(\.*)(\?*)/gi, ".\n")
      .replace(/(open)(\ )?(bracket)(\ *)(\,*)(\.*)(\ *)/gi, "(")
      .replace(/(\ *)(\,*)(close)(\ )?(bracket)/gi, ")")
      .replace(/centimetres|centimetre|CM|sem/gi, "cm")
      .replace(/\.{2,}/g, '.')
      .replace(/\,{2,}/g, ',')
      .replace(/\ {2,}/g, ' ');

    return text.trim();
  }

  handleUpdatePrescriotionVisbility = () => {

    {/* { IF APPOINEMENT IS COMPLETED OR ARRIVED AND PRESCRIPTION_BOOL IS TRUE SHOW UPDATE PRESCRIPTION TEXTAREA AND EDITABLE AND SHOW UPDATE PRESCRIPTION BUTTON*/ }
    {/* { NOTE MESSAGE WILL CHANGE FOR COMPLETE APPOINTMENT AND UPDATE PRESCRIPTION */ }
    {/* { IF APPOINEMENT IS COMPLETED OR ARRIVED AND PRESCRIPTION_BOOL IS FALSE THAN CHECK UPDATE_PRESCRIPTION_DATA !== NULL - JUST TO SHOW AND NON-EDITABLE */ }
    {/* { IF APPOINEMENT IS COMPLETED OR ARRIVED AND PRESCRIPTION_BOOL IS FALSE THAN CHECK UPDATE_PRESCRIPTION_DATA === NULL - DONT SHOW */ }
    {/* { UPDATE PRESCRIPTION BY HITTING MARK ARRIVAL API { UPDATE PRESCRIPTION DATA NOTHING ELSE WITHOUT } */ }
    {/* { IF UPLOAD_PRESCRIPTOIN !== NULL WE WON'T SHOW UPDATE PRESCRIPTION TEXT BOX EVEN IT PRESCRIPTION_BOOK IS TRUE } */ }

    const { appointment_status, pp_dataLoaded, appointment_details } = this.state;

    if (appointment_status === 5 && pp_dataLoaded) {
      if (appointment_details.upload_prescription === null) {
        if (appointment_details.prescription_bool || (!appointment_details.prescription_bool && appointment_details.update_prescription)) {
          return true;
        }
      }
    }

    return false;
  }

  render() {


    const { startedDianosis, startedMedicine, startedUpcomingPrescription, copyPrescription_data, copyDocumentsUploads, copyMedicineAdvised, copyNotesDoc,
      copySymptoms, copyUpdatedNotes, copyActiveId, handleSymptomsCheckedData, handleMediCheckedData, copyDataLoaded, isConfirmSymptomsBtn, isConfirmMedsDiaBtn, isSymptomsTextCheck, isMedicineDiagTextCheck,
      SymptomsIdGotChecked, MedicinesIdGotChecked, cpUploadPrescDate, appointment_status } = this.state;


    const copyPrescriptionBtn = appointment_status === 6 ? true : appointment_status === 1 ? true : false;


    return (
      <>
        <HeaderComponent />
        <SubHeaderComponent
          pageTitle={`${this.state.patient.name} ${this.state.patient.dob !== ""
            ? this.state.patient.dob + " Yrs,"
            : ""
            } ${this.state.patient.gender}`}
          subPageTitle={(this.state.patient.uhid) !== "" ? this.state.patient.uhid : ''}
          subPageTitle2={'Appointment Time: ' + BOOKINGTIME24TO12(this.state.patient.booking_time)}
          PrescriptionBtn={"yes"}
          PL={this.state.appointment_details.patient_id}
          btn_name={"Past VC Prescriptions"}
          patient_id={this.state.patient.id}
          patient={JSON.stringify(this.state.patient)}
          booking_id={this.state.appointment_details.id}
          booking_code={this.state.appointment_details.booking_code}
          allergies_btn={"yes"}
          ecg_btn={"yes"}
          onClick={this.openPreviousPrescription}
        />
        <div className={`container-fluid custom-container ${!this.state.pp_dataLoaded ? "hidden" : ""}`}>
          <div className="cf_wrapper d-flex flex-column">
            <div className={`row mt-3 ${this.state.consult_type !== 1 ? "hidden" : ""}`}>
              <div className="col-7">
                <div
                  className="video-btn"
                  id="patient_call_widget"
                  data-pid={this.state.patient.id}
                >
                  {this.state.canEndCall ? (
                    <button
                      title="Cancel Call"
                      type="button"
                      id="endconsult_call_btn"
                      className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                      onClick={() => this.endCall()}
                    >
                      <img
                        src={VideoIcon}
                        alt="video icon"
                        className="danger mr-2"
                      />
                      <img
                        src={VIconWhite}
                        alt="video icon"
                        className="danger mr-2"
                      />
                    END CALL
                    </button>
                  ) : (
                    <button
                      disabled={this.state.callPatientBtn}
                      title={
                        this.state.callPatientBtn
                          ? "Cannot call patient"
                          : "Call Patient"
                      }
                      type="button"
                      className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                      onClick={() => this.callUser()}
                    >
                      <img
                        src={VideoIcon}
                        alt="video icon"
                        className="danger mr-2"
                      />
                      <img
                        src={VIconWhite}
                        alt="video icon"
                        className="danger mr-2"
                      />
                    VIDEO CALL TO PATIENT
                    </button>
                  )}

                  <span className="call-message">
                    {this.state.callNotificationMsg}

                    {this.state.hungupExistingCall ? (
                      <button
                        title="Cancel Call"
                        type="button"
                        className="ml-3 img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                        onClick={() => this.endCall()}
                      >
                        <img
                          src={VideoIcon}
                          alt="video icon"
                          className="danger mr-2"
                        />
                        <img
                          src={VIconWhite}
                          alt="video icon"
                          className="danger mr-2"
                        />
                      END ONGOING CALL
                      </button>
                    ) : null}
                  </span>
                </div>
              </div>
              <div className="col">
                <div className="video-btn audio-btn">
                  <button title="Call Patient" type="button"
                    className="img-btn btn btn-outline-danger btn-call d-inline-flex justify-content-center align-items-center mr-3"
                    onClick={() => this.handleAudioCallPop(true)}>
                    <img src={AudioIcon} alt="video icon" className="danger mr-2" /><img src={AudioIconWhite} alt="video icon" className="danger mr-2" />
                    AUDIO CALL TO PATIENT
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              {this.state.consult_type === 1 ? (<div className="col-3">
                <div className="row video-conferencing-column">
                  {this.state.patient.number != null && this.state.video_call_driver == 2 ? (
                    <VideoConferencing
                      // ref="vcref"
                      patient={this.state.patient}
                      booking_id={this.state.appointment_details.id}
                      ishomecare={false}
                      location={this.props.location}
                      onChange={this.receiveInParent}
                    />
                  ) : null}

                  {this.state.patient.number != null && this.state.video_call_driver == 1 ? (
                    <QbVideoConferencing
                      // ref="vcref"
                      patient={this.state.patient}
                      booking_id={this.props.location.state.booking_id}
                      location={this.props.location}
                      onChange={this.receiveInParent}
                    />
                  ) : null}

                </div>
              </div>) : ""}
              <div className={`notes-section ${this.state.consult_type === 1 ? "col-9" : "col-12 notes-opd"}`}>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex flex-column notes-conent">
                          <div className="bg-light doctor-notes px-3">
                            <label className="label1 mt-3">NOTES FOR DOCTOR</label>
                            <p
                              className={`doc-notes-text float-left ${this.state.appointment_details.patient_note_doctor ===
                                null
                                ? "no-notes"
                                : ""
                                }`}
                            >
                              {this.state.appointment_details.patient_note_doctor ||
                                "No Notes"}
                            </p>
                          </div>

                          <div className="bg-light doctor-notes doctor-documents px-3 pb-2">
                            <label className="label1 mt-3">DOCUMENTS UPLOADED</label>
                            <div className="d-flex justify-content-start align-items-center">
                              {(this.state.uploads &&
                                this.state.uploads.fileDoc &&
                                this.state.uploads.fileDoc.length > 0) ||
                                (this.state.uploads &&
                                  this.state.uploads.fileImg &&
                                  this.state.uploads.fileImg.length > 0) ? (
                                <div className="">
                                  {this.state.uploads &&
                                    this.state.uploads.fileImg &&
                                    this.state.uploads.fileImg.length > 0
                                    ? this.state.uploads.fileImg.map((file, i) => {
                                      return (
                                        <button
                                          className="btn btn-outline-light p-0 mr-1"
                                          key={i}
                                          onClick={(e) => this.documentToggle(e, [...this.state.uploads.fileImg, ...this.state.uploads.fileDoc])}
                                        >
                                          <img
                                            src={jpg}
                                            alt="pdf icon"
                                            className="pdf"
                                          ></img>
                                        </button>
                                      );
                                    })
                                    : ""}

                                  {this.state.uploads &&
                                    this.state.uploads.fileDoc &&
                                    this.state.uploads.fileDoc.length > 0
                                    ? this.state.uploads.fileDoc.map((file, i) => {
                                      return (
                                        <button
                                          className="btn btn-outline-light p-0 mr-1"
                                          key={i}
                                          onClick={(e) => this.documentToggle(e, [...this.state.uploads.fileImg, ...this.state.uploads.fileDoc], true)}
                                        >
                                          <img
                                            src={pdf}
                                            alt="pdf icon"
                                            className="pdf"
                                          ></img>
                                        </button>
                                      );
                                    })
                                    : ""}
                                </div>
                              ) : (<p className="doc-notes-text no-notes m-0">
                                Nil
                              </p>)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="bg-light doctor-notes px-3">
                          <label className="label1 mt-3">PAST VC PRESCRIPTION</label>
                          <div
                            className={`doc-notes-text float-left scroll_loader ${this.state.past_prescriptions.length === 0 &&
                              this.state.pp_dataLoaded
                              ? "no-notes"
                              : ""
                              }`}
                          >
                            {this.state.past_prescriptions.length > 0
                              ? (<ul className="list-unstyled past-prescriptionlist m-0">
                                {this.state.past_prescriptions.map((elem, i) => (
                                  <li key={i} className="d-flex justify-content-between align-items-center">
                                    <div>{elem.doctor_name}</div>
                                    <div>
                                      <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
                                        {elem.date}
                                      </Moment>
                                    </div>
                                    <div>
                                      <button className="btn btn-link" onClick={() => this.openPrescriptionDoc(elem.id)} >VIEW Rx</button>
                                    </div>
                                  </li>
                                ))}
                              </ul>)
                              : (this.state.past_prescriptions.length === 0 && this.state.pp_dataLoaded ? "No Past Prescriptions" : "")}

                            <div className="col justify-content-center align-self-center"
                              style={{
                                display: `${!this.state.pp_dataLoaded || this.state.isPastPrescriptionRequestProcessing ? "flex" : "none"}`,
                                margin: `${!this.state.pp_dataLoaded ? "30px 0" : ""}`,
                              }}
                            >
                              {LOADER_RED}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row doctor-presandupdate">
                      <div className="col-12">
                        <div className="text-feild">
                          <label className="label1">SYMPTOMS &amp; DIAGNOSIS</label>
                          <button type="button" className={`icon-mute-btn ${startedDianosis ? 'active' : ""}
                            ${this.state.appointment_status === 5 || startedMedicine || startedUpcomingPrescription || isConfirmSymptomsBtn || isConfirmMedsDiaBtn ? "disabled" : ""}`}
                            title="Microphone" name="diagnosis_notes" onClick={this.toggleListen}>
                            <img src={mic_icon} alt="mic-icon" />
                            <span></span>
                          </button>
                          <textarea
                            tabIndex="1"
                            className={`text-feild1 ${isConfirmSymptomsBtn ? "confirm-text" : ""} `}
                            rows="5"
                            cols="20"
                            placeholder="EX : weakness for 2-3 days"
                            name="diagnosis_notes"
                            ref={this.diagnosisNotesRef}
                            value={this.state.diagnosis_notes}
                            onChange={this.handleInputChange}
                            disabled={this.state.appointment_status === 5 ? true : (isConfirmSymptomsBtn ? true : false)}
                          ></textarea>
                          {copyPrescriptionBtn ? (
                            <div>
                              {!isConfirmSymptomsBtn ? (
                                <button className="btn btn-link text-d24350 copy-presc" onClick={this.handleCopyPrescription}>Copy Prescription</button>
                              ) : (
                                <button className="btn btn-link text-d24350 copy-presc" onClick={() => this.handleConfirmCP(0)} >
                                  <img src={Tick} alt="tick-img" />Confirm
                                </button>
                              )}
                            </div>
                          ) : ("")}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="text-feild">
                          <label className="label1">
                            MEDICINES &amp; TESTS ADVISED
                          </label>
                          <button type="button" className={`icon-mute-btn ${startedMedicine ? 'active' : ""}
                            ${this.state.appointment_status === 5 || startedDianosis || startedUpcomingPrescription || isConfirmMedsDiaBtn || isConfirmSymptomsBtn ? "disabled" : ""}`}
                            title="Microphone" name="medicine_test" onClick={this.toggleListen}>
                            <img src={mic_icon} alt="mic-icon" />
                            <span></span>
                          </button>
                          <textarea
                            tabIndex="2"
                            className={`text-feild1 ${isConfirmMedsDiaBtn ? "confirm-text" : ""}`}
                            rows='5'
                            cols='20'
                            placeholder='EX : tab lcg 5mg'
                            name='medicines_tests'
                            ref={this.medicineNotesRef}
                            value={this.state.medicines_tests}
                            disabled={this.state.appointment_status === 5 ? true : (isConfirmMedsDiaBtn ? true : false)}
                            onChange={this.handleInputChange}></textarea>
                          {copyPrescriptionBtn ? (
                            <div>
                              {!isConfirmMedsDiaBtn ? (
                                <button className="btn btn-link text-d24350 copy-presc" onClick={this.handleCopyPrescription}>Copy Prescription</button>
                              ) : (
                                <button className="btn btn-link text-d24350 copy-presc" onClick={() => this.handleConfirmCP(1)} ><img src={Tick} alt="tick-img" />Confirm</button>
                              )}
                            </div>
                          ) : ("")}

                        </div>
                      </div>

                      {this.handleUpdatePrescriotionVisbility() ? (
                        <div className="col-12">
                          <div className="text-feild">
                            <label className="label1">
                              UPDATE PRESCRIPTION
                            </label>
                            <button type="button" className={`icon-mute-btn ${startedUpcomingPrescription ? 'active' : ""}
                              ${startedDianosis || startedMedicine || !this.state.appointment_details.prescription_bool ? "disabled" : ""}`}
                              title="Microphone" name="update_prescription" onClick={this.toggleListen}>
                              <img src={mic_icon} alt="mic-icon" />
                              <span></span>
                            </button>
                            <textarea
                              tabIndex="1"
                              className="text-feild1"
                              rows="5"
                              cols="20"
                              placeholder="EX : weakness for 2-3 days"
                              name="update_prescription_text"
                              ref={this.updatepresctiptionRef}
                              value={this.state.update_prescription_text}
                              onChange={this.handleInputChange}
                              disabled={!this.state.appointment_details.prescription_bool}
                            ></textarea>
                          </div>
                        </div>) : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col d-flex align-items-center justify-content-end">
                {this.state.appointment_status !== 5 ? (
                  <>
                    <span className="mr-2">On clicking “COMPLETE APPOINTMENT” the prescription will be sent to the patient; In case you wish to update your prescription, you can do so within 24 hours of completing the appointment.</span>
                    <button
                      id="completed_consult_btn"
                      className="btn btn-danger complete-btn"
                      onClick={this.handleCompleteAppointmentClick}
                      disabled={this.state.completeAppointmentBtnValid}
                    >
                      COMPLETE APPOINTMENT
                  </button>
                  </>) : (
                  this.handleUpdatePrescriotionVisbility() && this.state.appointment_details.update_prescription === null ? (
                    <>
                      <span className="mr-2">On clicking “UPDATE PRESCRIPTION” the prescription will be sent to the patient; You will not be able to make any further changes to this prescription once you update the prescription.</span>
                      <button
                        id="completed_consult_btn"
                        className="btn btn-danger complete-btn"
                        onClick={this.handleCompleteAppointmentClick}
                        disabled={this.state.updatePrescriptionBtnValid}
                      >
                        UPDATE PRESCRIPTON
                   </button>
                    </>
                  ) : "")}
              </div>
            </div>
          </div>
        </div>

        <div className="col justify-content-center align-self-center"
          style={{
            display: `${!this.state.pp_dataLoaded ? "flex" : "none"}`,
            margin: `${!this.state.pp_dataLoaded ? "30px 0" : ""}`,
          }}
        >
          {LOADER_RED}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.documentToggle}
          className={this.state.modalClass}
        >
          <ModalHeader toggle={this.documentToggle}></ModalHeader>
          <ModalBody>
            <div className="img-rotate">
              <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
              <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
            </div>
            <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
              <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
              <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
            </div>
            <div className="object-wrapper ow-fullheight">
              {this.state.documentType === 'application/pdf' ?
                <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object> : (
                  <figure className="zoom" style={{ backgroundImage: `url('${this.state.dataFile}')`, backgroundSize: '150%', backgroundRepeat: 'no-repeat' }} id='objdoc1' data-rotate="0" onMouseMove={((e) => ZOOM_IN(e, this.state.dataFile))}>
                    <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object>
                  </figure>
                )}

            </div>
          </ModalBody>
        </Modal>

        <Popup
          className="copy-prescription"
          open={this.state.openCopyPrescription}
          onClose={() => this.setState({ openCopyPrescription: false })}
          modal
        >
          <div className="popup">
            <div className="d-flex p-3">
              <div className="title">
                <h5 className="m-0">Copy Prescription</h5>
              </div>
              <div className="close copy-prescription" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleCpReset()} >
                &times;
              </div>
            </div>
            <div className="content">
              {copyDataLoaded && copyPrescription_data.length > 0 ? (
                <section className="d-flex  flex-grow-1 copy_prescription">
                  <aside className="aside-copy cp_scroll_loader">
                    <div>
                      {copyPrescription_data.map((ele, index) => (
                        <div className={`border-bottom p-3 aside-data ${copyActiveId === ele.id ? "active" : ""}`} key={index} onClick={() => this.handleShowCopyData(ele.id)}>
                          <h6 className="m-0">{ele.doctor_name}</h6>
                          <p className="mb-2 cp-patient-name">{ele.patient_name.toLowerCase()}</p>
                          <p>
                            <Moment format="h A, D MMM Y" parse="YYYY-MM-DD HH:mm">
                              {ele.date}
                            </Moment>
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="col justify-content-center align-self-center"
                      style={{
                        display: `${this.state.isCpReqProcessing ? "flex" : "none"}`,
                        margin: `${this.state.isCpReqProcessing ? "30px 0" : ""}`,
                      }}
                    >
                      {LOADER_RED}
                    </div>
                  </aside>
                  <div className="px-3 pt-3 pb-5 bg-grey notes-main overflow-auto">
                    <div className="row">
                      <div className="col-6 pr-1 mb-2">
                        <div className="bg-light doctor-notes px-3">
                          <label className="label1 mt-3">NOTES FOR DOCTOR</label>
                          <p className="doc-notes-text  copy-pre-notes float-left">
                            {copyNotesDoc === null ? "No Notes" : copyNotesDoc}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 pl-1 mb-2">
                        <div className="bg-light doctor-notes px-3">
                          <label className="label1 mt-3">DOCUMENTS UPLOADED</label>
                          <div className="doc-notes-text copy-pre-notes float-left">
                            {(copyDocumentsUploads &&
                              copyDocumentsUploads.fileDoc &&
                              copyDocumentsUploads.fileDoc.length > 0) ||
                              (copyDocumentsUploads &&
                                copyDocumentsUploads.fileImg &&
                                copyDocumentsUploads.fileImg.length > 0) ? (
                              <div className="">
                                {copyDocumentsUploads &&
                                  copyDocumentsUploads.fileImg &&
                                  copyDocumentsUploads.fileImg.length > 0
                                  ? copyDocumentsUploads.fileImg.map((file, i) => {
                                    return (
                                      <button
                                        className="btn btn-outline-light p-0 mr-1"
                                        key={i}
                                        onClick={(e) => this.documentToggle(e, [...copyDocumentsUploads.fileImg, ...copyDocumentsUploads.fileDoc])}
                                      >
                                        <img
                                          src={jpg}
                                          alt="pdf icon"
                                          className="pdf-cp"
                                        ></img>
                                      </button>
                                    );
                                  })
                                  : ""}

                                {copyDocumentsUploads &&
                                  copyDocumentsUploads.fileDoc &&
                                  copyDocumentsUploads.fileDoc.length > 0
                                  ? copyDocumentsUploads.fileDoc.map((file, i) => {
                                    return (
                                      <button
                                        className="btn btn-outline-light p-0 mr-1"
                                        key={i}
                                        onClick={(e) => this.documentToggle(e, [...copyDocumentsUploads.fileImg, ...copyDocumentsUploads.fileDoc], true)}
                                      >
                                        <img
                                          src={pdf}
                                          alt="pdf icon"
                                          className="pdf-cp"
                                        ></img>
                                      </button>
                                    );
                                  })
                                  : ""}
                              </div>
                            ) : (<p className="doc-notes-text no-notes m-0">
                              Nil
                            </p>)}
                          </div>
                        </div>
                      </div>
                      <div className="col-6 pr-1 mb-2">
                        <div className="bg-light doctor-notes px-3">
                          <label className="label1 mt-3">SYMPTOMS &amp; DIAGNOSIS</label>
                          <p className="doc-notes-text copy-pre-notes float-left mb-5">
                            {copySymptoms === null ? "No Notes" : copySymptoms}
                          </p>
                          <div className="custom-control custom-checkbox copy-prec-checkbox">
                            <input type="checkbox" className="custom-control-input "
                              id="orderset_7" name="orderset" checked={isSymptomsTextCheck} disabled={copySymptoms === null}
                              onChange={() => this.handleSymptomsChecked()} />
                            <label className={`custom-control-label mr-3 mb-3 cp-label-size text-d24350 ${copySymptoms === null ? "" : "cursor-pointer"} `}
                              htmlFor="orderset_7">Copy</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 pl-1 mb-2">
                        <div className="bg-light doctor-notes px-3">
                          <label className="label1 mt-3">MEDICINES &amp; TESTS ADVISED</label>
                          <p className="doc-notes-text copy-pre-notes float-left mb-5">
                            {copyMedicineAdvised === null ? "No Notes" : copyMedicineAdvised}
                          </p>
                          <div className="custom-control custom-checkbox copy-prec-checkbox">
                            <input type="checkbox" className="custom-control-input "
                              id="orderset_1" name="orderset" checked={isMedicineDiagTextCheck} disabled={copyMedicineAdvised === null}
                              onChange={() => this.handleMedicineChecked()} />
                            <label className={`custom-control-label mr-3 mb-3 cp-label-size text-d24350 ${copyMedicineAdvised === null ? "" : "cursor-pointer"} `}
                              htmlFor="orderset_1">Copy</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 mb-2"></div>
                      <div className="col-6 pl-1 mb-2">
                        <div className="bg-light doctor-notes px-3 bg-ffefef-pink">
                          <label className="label1 mt-3">UPDATED PRESCRIPTION</label>
                          <p className="doc-notes-text copy-pre-updated_notes">
                            {copyUpdatedNotes === null ? "No Notes" : copyUpdatedNotes}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-12 pl-1 rog">
                        <button className="btn btn-danger my-3 px-4 font-weight-bold" onClick={() => this.handleCheckboxCopy()}>COPY</button>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-9 copy-pres-btn position-absolute ">
                        <button className="btn btn-danger my-3 px-4 font-weight-bold" disabled={isSymptomsTextCheck || isMedicineDiagTextCheck ? false : true} onClick={() => this.handleCheckboxCopy()}>COPY</button>
                      </div>
                    </div>

                  </div>
                </section>
              ) : ""}
              {copyDataLoaded && copyPrescription_data.length === 0 ? (
                <div className="d-flex justify-content-center">
                  <h5 className="text-danger">No Data Found</h5>
                </div>
              ) : (
                <div className="col justify-content-center align-self-center"
                  style={{
                    display: `${!copyDataLoaded ? "flex" : "none"}`,
                    margin: `${!copyDataLoaded ? "30px 0" : ""}`,
                  }}
                >
                  {LOADER_RED}
                </div>
              )}

            </div>

          </div>
        </Popup>

        <Popup
          className="cancel-pop"
          open={this.state.openhandleCompleteAppointmentButtonPop}
          onClose={() => this.handleCompleteAppointmentButton(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleCompleteAppointmentButton(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                {this.handleUpdatePrescriotionVisbility() && this.state.appointment_details.update_prescription === null ? (
                  "Your signature is being added to this prescription. You will not be able to make any further changes to this prescription once you update the prescription."
                ) : (
                  "Your signature is being added to this prescription. In case you wish to update your prescription, you can do so within 24 hours of completing the appointment."
                )}
              </p>
            </div>
            {QbHelpers.isCallActive || !this.state.videoEndCall ?
              (<div className="pr-3 button text-center">
                <button
                  className="btn btn-outline-dark cancel-btn button-in mr-2"
                  onClick={(e) =>
                    this.completeAppointment(e, this.state.appointment_details.id)
                  }
                >
                  SEND PRESCRIPTION AND CONTINUE WITH CALL
                  <span className="loader-span">{LOADER_BLACK}</span>
                </button>
                <button
                  className="btn btn-danger mt-4 mb-4 button-in"
                  onClick={(e) =>
                    this.endCallAndCompleteAppointment(e,
                      this.state.appointment_details.id
                    )
                  }
                >
                  SEND PRESCRIPTION AND END CALL
                  <span className="loader-span">{LOADER}</span>
                </button>

              </div>)
              : this.state.videoEndCall ?
                (<div className="pr-3 button text-center">
                  <button
                    className="btn btn-danger mt-4 mb-4 button-in"
                    onClick={(e) =>
                      this.completeAppointment(e,
                        this.state.appointment_details.id
                      )
                    }
                  >
                    {" "}
                  SEND PRESCRIPTION TO PATIENT
                  <span className="loader-span">{LOADER}</span>
                  </button>
                </div>) : ""
            }
          </div>
        </Popup>

        {/* <Popup
          className="cancel-pop"
          open={this.state.openPrescriptionSentPop}
          onClose={() => this.handlePrescriptionSentPop(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handlePrescriptionSentPop(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                Your prescription has been sent to the patient.
              </p>
            </div>
            <div className="pr-3 button text-center">
              <button
                className="btn btn-outline-dark cancel-btn button-in mr-2"
                onClick={() =>
                  this.handlePrescriptionSentPop(false)
                }
              >
                CONTINUE WITH CALL
              </button>
              <button
                className="btn btn-danger mt-4 mb-4 button-in"
                onClick={() =>
                  this.completeAppointment(
                    this.state.appointment_details.id
                  )
                }
              >
                END CALL
              </button>

            </div>
          </div>
        </Popup> */}

        <Popup
          className="cancel-pop"
          open={this.state.openAudioCallPop}
          onClose={() => this.handleAudioCallPop(false)}
          modal
        >
          <div className="popup">
            <div className="close" style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.handleAudioCallPop(false)}>
              &times;
            </div>
            <div className="content mt-5 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                You will receive a call on your mobile phone, please answer to connect with the patient.
              </p>
            </div>
            <div className="pr-3 button text-center">

              <button
                className="btn btn-danger mt-4 mb-4 button-in mr-2"
                onClick={this.handleAUdioCallApi}
              >
                CONNECT WITH PATIENT
                <span className="loader-span">{LOADER}</span>
              </button>

              <button
                className="btn btn-outline-dark cancel-btn button-in"
                onClick={() => this.handleAudioCallPop(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </Popup>

        <Modal isOpen={this.state.handleCallFeedbackPopup}>
          <ModalHeader
            className="text-center bg-grey">Please rate the quality of your call</ModalHeader>
          <ModalBody className="text-center">
            <div className="beautify_wrapper d-flex flex-column">
              <BeautyStars
                activeColor="#d24350"
                inactiveColor="#F0F0F0"
                value={this.state.doctor_rating}
                onChange={doctor_rating => this.setDoctorRating(doctor_rating)} />
              <span className="mt-3 hidden error-beautystars text-danger">Please rate to Submit!</span>
            </div>
            <br />
            <Input type="textarea" placeholder="Remarks" name="doctor_remarks" value={this.state.doctor_remarks} rows={3}
              onChange={this.handleInputChange} />
          </ModalBody>
          <div className="d-flex text-center modal-header p-0">
            <div className="row justify-content-center">
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-cancel font-weight-bold"
                  type="button" onClick={this.handleDontRate} >
                  Not now
                </Button>
              </div>
              <div className="col-md-auto">
                <Button className="button-healthvc button-variant-red mb-3 font-weight-bold btn btn-primary"
                  type="button" onClick={this.handleBeautyPopupSubmit} >
                  Submit
                <span className="loader-span">{LOADER}</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <ToastComponent
          successClass={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />

        <Bandwidth booking_id={this.props.location.state.booking_id} />

      </>
    );
  }
}

export default withRouter(DocComp);
