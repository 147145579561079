import { Subject } from 'rxjs';

const subject = new Subject();
export const pubnubService$ = {
    pubnubChatShow: data => subject.next(data),
    pubNubBooking_data: data => subject.next(data),
    pubnub_active_chats: data => subject.next(data),
    pubnub_message_count: data => subject.next(data),
    pubnub_notification_update: data => subject.next(data),
    pubnub_enable_chat: data => subject.next(data),
    pubnub_observe: () => subject.asObservable()
};