import React, { Component } from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import PDF_ICO from "../../assets/images/pdf.png";
import JPG_ICO from "../../assets/images/jpeg.png";
import rotateLeft from "../../assets/images/rotate-left.png";
import rotateRight from "../../assets/images/rotate-right.png";
import prevImgIcon from "../../assets/images/previous-icon.svg";
import nextImgIcon from "../../assets/images/next-icon.svg";
import HeaderComponent from "../../components/header/headerComponent";
import LS_SERVICE from "../../utils/localStorage";
import $ from "jquery";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  API_BASE_URL2,
  API_BASE_URL3,
  SEPARATEUPLOADEDDOCSPAST,
  LOADER_RED,
  HANDLEIMAGEROTATE,
  IMAGEPREVNEXTRESET,
  GETURLEXTENSION,
  PAST_PRESCRIPTION_API,
  SEPARATEUPLOADEDDOCS,
  ZOOM_IN
} from "../../utils/constant";
import { Container, ListGroup } from "react-bootstrap";
import Moment from "react-moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ImageMaginify from "../../utils/imageMaginify";

class pastVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploads: [],
      photoIndex: 0,
      isOpen: false,
      lightImages: [],
      reports: [],
      past_prescription_page: 1,
      total_prescription_pages: 1,
      noMorePastPrescriptionData: false,
      isPastPrescriptionRequestProcessing: false,
      modal: false,
      dataFile: "",
      modalClass: "modal-imgdocs",
      documentIndex: 0,
      documentCount: 0,
      documentType: "",
      allDocuments: [],
      cards: [],
      pp_dataLoaded: false,
      is_homecare: null,
      is_doctor: false,
      patient_id: null,
      booking_id: null,
      activeTab: null
    };
    this.displayPages = this.displayPages.bind(this);
    this.makeList = this.makeList.bind(this);
    this.backButton = this.backButton.bind(this);

    if (props.location.state === undefined) {
      props.history.push("/patient-list");
      return;
    }
  }

  UNSAFE_componentWillMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
    this.setState({
      is_homecare: this.props.location.state.is_homecare,
      is_doctor: LS_SERVICE.get('is_doctor'),
      patient_id: this.props.location.state.patient_id,
      booking_id: this.props.location.state.booking_id,
      activeTab: this.props.location.state.is_homecare ? 'homecare' : 'virtual'
    })
  }

  componentDidMount() {
    this.addScrollEvent();
    this.pastPresciptions();
  }

  componentWillUnmount() {
    this.cleanupListener();
  }

  addScrollEvent = () => {
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleScroll, true);
  };

  cleanupListener = () => {
    window.removeEventListener("scroll", this.handleScroll, true);
    window.removeEventListener("resize", this.handleScroll, true);
  };

  checkScrollSpaceAvailability = () => {
    const lastLi = document.querySelector(
      ".scroll_data > div:last-child"
    );
    const scrollWin = document.querySelector(".scrollContainer");
    if (lastLi === null) return;
    if (scrollWin.scrollTop === 0) {
      const listLiTotalHeight = lastLi.clientHeight * (document.querySelectorAll(".scroll_data > div").length / 2);
      const pageOffset = scrollWin.scrollTop + scrollWin.clientHeight;
      if (pageOffset > listLiTotalHeight) {
        this.loadMore();
      }
    }
  }

  handleScroll = () => {
    const lastLi = document.querySelector(
      ".scroll_data > div:last-child"
    );
    const scrollWin = document.querySelector(".scrollContainer");
    if (lastLi === null) return;
    var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
    let pageOffset = scrollWin.scrollTop + scrollWin.scrollHeight;
    if (pageOffset > lastLiOffset) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { noMorePastPrescriptionData, isPastPrescriptionRequestProcessing } = this.state;

    if (noMorePastPrescriptionData) return;
    if (isPastPrescriptionRequestProcessing === true) return;

    this.setState({
      isPastPrescriptionRequestProcessing: true,
    });

    this.setState(
      (prevState) => ({
        past_prescription_page: prevState.past_prescription_page + 1
      }),
      () => {
        this.pastPresciptions()
      }
    );
  };

  pastPresciptions = () => {

    let { past_prescription_page, activeTab, is_doctor, patient_id, is_homecare } = this.state;

    is_homecare = is_homecare ? 1 : 0;
    const hospital_id = LS_SERVICE.get('active_hospital').id

    // if (activeTab === "virtual") {
    // URL = `${API_BASE_URL3}patient-past-prescription?patient_id=${this.state.patient_id}&page=${past_prescription_page}`;
    let URL = `${PAST_PRESCRIPTION_API({ is_doctor, patient_id, activeTab, is_homecare, hospital_id })}&page=${past_prescription_page}`;
    // } else {
    // URL = `${API_BASE_URL3}patient-past-prescription?patient_id=${this.state.patient_id}&is_homecare=1&page=${past_prescription_page}`;
    // URL = `${PAST_PRESCRIPTION_API({is_doctor, activeTab, patient_id, is_homecare})}&page=${past_prescription_page}`;
    // }

    axios
      .get(URL)
      .then((response) => {

        const dataNew = response.data.reports;
        const tPages = response.data.pagination.total_pages;
        const noData = response.data.pagination.links.next === "" ? true : false;
        const {
          reports
        } = this.state;

        this.setState({
          reports: past_prescription_page !== 1 ? [...reports, ...dataNew] : [...dataNew],
          pp_dataLoaded: true,
          total_prescription_pages: tPages,
          isPastPrescriptionRequestProcessing: false,
          noMorePastPrescriptionData: noData,
        });
        this.makeList();
      })
      .catch((error) => {
        this.setState({
          pp_dataLoaded: true,
        });
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }


  handleWorkListNav = () => {
    this.props.history.push("/patient-list");
  };

  backButton() {

    const { is_homecare } = this.state;

    if (is_homecare) {
      this.props.history.push({
        pathname: "/hc-consult",
        state: {
          booking_id: this.state.booking_id,
          patient_id: this.state.patient_id,
          is_homecare
        },
      });
    } else {
      this.props.history.push({
        pathname: "/doctor-consult",
        state: {
          booking_id: this.state.booking_id,
          patient_id: this.state.patient_id,
        },
      });
    }
  }

  progressButton() {
    this.props.history.push({
      pathname: "/progress-notes",
      state: {
        booking_id: this.state.booking_id,
        patient_id: this.state.patient_id,
      },
    });
  }

  resetStateLightbox() {
    this.setState({
      isOpen: false,
      lightImages: [],
      photoIndex: 0,
    });
  }

  receiveInParent(callInfo) {
    if (typeof callInfo !== "undefined") {
      this.setState({
        callPatientBtn: callInfo.callPatientBtn,
        callNotificationMsg: callInfo.callNotificationMsg,
      });
    }
  }

  displayPages(value) {
    this.resetStateLightbox();
    this.setState({
      isOpen: true,
      lightImages: value,
    });
  }

  documentToggle = (e, files, isDoc = false) => {
    if (files !== undefined) {
      if (isDoc) {
        this.setState({
          modalClass: "modal-imgdocs modal-docs",
          documentType: 'application/pdf',
        });
      } else {
        this.setState({
          modalClass: "modal-imgdocs",
          documentType: '',
        });
      }
      if (e !== null) {
        e.preventDefault();
        this.setState({
          documentIndex: $(e.currentTarget).index(),
          documentCount: e.currentTarget.parentElement.childElementCount

        }, () => {
          this.setState({
            showArrow: this.state.documentCount > 1 ? true : false,
          })
        })

        this.setState({
          allDocuments: files,
          dataFile: files[$(e.currentTarget).index()],
        });
      } else {
        this.setState({
          dataFile: files,
        })
      }
    }

    this.setState({
      modal: !this.state.modal,
    }
      // ,()=>setTimeout(()=>{
      //   this.imageZoom("objDoc","objdoc1")
      // },0)
    );
  };

  handleNextPrev = (e) => {
    e.preventDefault();
    const { documentIndex, allDocuments, documentCount } = this.state;
    let docIndex = documentIndex;
    const targetName = e.currentTarget;
    if (targetName.getAttribute("name") === "image-left") {
      if (docIndex > 0) {
        this.setState({
          documentIndex: documentIndex - 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex - 1]),
          dataFile: allDocuments[docIndex - 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    } else {
      if (docIndex < (documentCount - 1)) {
        this.setState({
          documentIndex: documentIndex + 1,
          documentType: GETURLEXTENSION(allDocuments[docIndex + 1]),
          dataFile: allDocuments[docIndex + 1]
        }, () => {
          if (this.state.documentType === "application/pdf") {
            this.setState({
              modalClass: "modal-imgdocs modal-docs",
            })
          } else {
            this.setState({
              modalClass: "modal-imgdocs",
            })
          }
          IMAGEPREVNEXTRESET(this.state.dataFile);
        })
      }
    }
  }

  openPrescriptionDoc(booking_id) {
    axios.get(API_BASE_URL2 + "bookings/" + booking_id + "/prescriptions?mode=web")
      .then((Response) => {
        var pdfUrl = Response.data;
        this.documentToggle(null, pdfUrl, true)
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  openPrescriptionDocHomecare(URL) {
    axios.get(URL + '?mode=web')
      .then((Response) => {
        var pdfUrl = Response.data;
        this.documentToggle(null, pdfUrl, true)
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }


  trimString(notesString) {
    var maxLength = 165;
    var trimmedString = "";
    // notesString = notesString.replaceAll('\\n', ' ');
    if (notesString && notesString.length < maxLength) {
      trimmedString = notesString
    }
    else if (notesString && notesString.length > trimmedString) {
      trimmedString = notesString.substr(0, maxLength);
      trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      );
      trimmedString = trimmedString + '...'
    }
    return trimmedString;
  }


  makeList() {
    const cards = [];
    var reports = this.state.reports;

    reports.forEach((value, index) => {
      if (this.state.activeTab === "virtual") {
        const uploads = SEPARATEUPLOADEDDOCSPAST(value.patient_docments, value.id);
        cards.push(
          <div className="col-12" key={index}>
            <div>
              <Card className="mb-3">
                <Card.Body className="card-bodycustom ">
                  <Row className="d-flex" style={{ flexWrap: "nowrap" }}>
                    <Col md={2}>
                      <div>
                        <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-0">
                          {value.doctor_name}
                        </Card.Title>
                        <h6 className="healtvc-apptime pv text-danger   mb-0 d-inline-flex justify-content-center align-items-center" >
                          <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
                            {value.date}
                          </Moment>
                        </h6>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="pl-2">
                        <Card.Title className="healtvc-card-title text-dark mb-2">
                          {value.patient_note_doctor ? (
                            <span className="healthvc-tick"></span>
                          ) : null}
                          Notes for Doctor
                        </Card.Title>
                        <Card.Text className="card-light-gray pc-doctor-notes pcd-notes">
                          {value.patient_note_doctor !== null
                            ? this.trimString(value.patient_note_doctor)
                            : "Nil"}
                        </Card.Text>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="pl-2">
                        <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-2">
                          {!uploads.fileImg.length > 0 &&
                            !uploads.fileDoc.length > 0 ? null : (
                              <span className="healthvc-tick"></span>
                            )}
                          Documents Uploaded
                        </Card.Title>
                        <Card.Text className="pc-doctor-notes">
                          {!uploads.fileImg.length > 0 &&
                            !uploads.fileDoc.length > 0
                            ? "Nil"
                            : null}
                          {uploads.fileImg.length > 0
                            ? uploads.fileImg.map((file, i) => {
                              return (
                                <button
                                  className="btn btn-outline-light p-0 ml-1"
                                  key={i}
                                  onClick={(e) => this.documentToggle(e, [...uploads.fileImg, ...uploads.fileDoc])}
                                >
                                  <Image
                                    src={JPG_ICO}
                                    alt="PDF ICON"
                                    style={{ height: "30px" }}
                                  />
                                </button>
                              );
                            })
                            : null}
                          {uploads.fileDoc.length > 0
                            ? uploads.fileDoc.map((file, i) => {
                              return (
                                <button
                                  className="btn btn-outline-light p-0 ml-1"
                                  key={i}
                                  onClick={(e) => this.documentToggle(e, [...uploads.fileImg, ...uploads.fileDoc], true)}
                                >
                                  <Image
                                    src={PDF_ICO}
                                    alt="PDF ICON"
                                    style={{ height: "30px" }}
                                  />
                                </button>
                              );
                            })
                            : null}
                        </Card.Text>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="d-flex flex-column justify-content-around">
                        <Button
                          type="button"
                          className="button-healthvc button-variant-red-view mb-3 font-weight-bold"
                          onClick={(e) => this.openPrescriptionDoc(value.id)}
                        >
                          VIEW Rx
                            </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        );
      } else {
        const uploads = SEPARATEUPLOADEDDOCS(value.patient_docments);
        cards.push(
          <div className="col-12" key={index}>
            <div>
              <Card className="mb-3">
                <Card.Body className="card-bodycustom ">
                  <Row className="d-flex" style={{ flexWrap: "nowrap" }}>
                    <Col md={2}>
                      <div>
                        <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-0">
                          {value.doctor_name}
                        </Card.Title>
                        <h6 className="healtvc-apptime pv text-danger   mb-0 d-inline-flex justify-content-center align-items-center" >
                          <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
                            {value.prescription_date}
                          </Moment>
                        </h6>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="pl-2">
                        <Card.Title className="healtvc-card-title text-dark mb-2">
                          {value.patient_note_doctor ? (
                            <span className="healthvc-tick"></span>
                          ) : null}
                        Notes for Doctor
                      </Card.Title>
                        <Card.Text className="card-light-gray pc-doctor-notes pcd-notes">
                          {value.patient_note_doctor !== ""
                            ? this.trimString(value.patient_note_doctor)
                            : "Nil"}
                        </Card.Text>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="pl-2">
                        <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-2">
                          {!uploads.fileImg.length > 0 &&
                            !uploads.fileDoc.length > 0 ? null : (
                              <span className="healthvc-tick"></span>
                            )}
                        Documents Uploaded
                      </Card.Title>
                        <Card.Text className="pc-doctor-notes">
                          {!uploads.fileImg.length > 0 &&
                            !uploads.fileDoc.length > 0
                            ? "Nil"
                            : null}
                          {uploads.fileImg.length > 0
                            ? uploads.fileImg.map((file, i) => {
                              return (
                                <button
                                  className="btn btn-outline-light p-0 ml-1"
                                  key={i}
                                  onClick={(e) => this.documentToggle(e, [...uploads.fileImg, ...uploads.fileDoc])}
                                >
                                  <Image
                                    src={JPG_ICO}
                                    alt="PDF ICON"
                                    style={{ height: "30px" }}
                                  />
                                </button>
                              );
                            })
                            : null}
                          {uploads.fileDoc.length > 0
                            ? uploads.fileDoc.map((file, i) => {
                              return (
                                <button
                                  className="btn btn-outline-light p-0 ml-1"
                                  key={i}
                                  onClick={(e) => this.documentToggle(e, [...uploads.fileImg, ...uploads.fileDoc], true)}
                                >
                                  <Image
                                    src={PDF_ICO}
                                    alt="PDF ICON"
                                    style={{ height: "30px" }}
                                  />
                                </button>
                              );
                            })
                            : null}
                        </Card.Text>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="d-flex flex-column justify-content-around">
                        <Button
                          type="button"
                          className="button-healthvc button-variant-red-view mb-3 font-weight-bold"
                          onClick={(e) => this.openPrescriptionDocHomecare(value.pdf_document)}
                        >
                          VIEW Rx
                      </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        );
      }
    });

    if (reports.length === 0) {
      cards.push(
        <div className="col-12" key={1}>
          <div>
            <Card className="mb-3">
              <Card.Body className="card-bodycustom card-pastVisit">
                <Row className="d-flex" style={{ flexWrap: "nowrap" }}>
                  <Col className="d-flex justify-content-center">
                    <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-0">
                      No Past Visits
                    </Card.Title>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      );
    }

    this.setState({
      cards: cards,
      pp_dataLoaded: true
    }, () => this.checkScrollSpaceAvailability());
  }

  handleTabClick = (e, activeTab) => {
    e.preventDefault();

    const { isPastPrescriptionRequestProcessing } = this.state;
    if (isPastPrescriptionRequestProcessing) return;

    this.setState({
      activeTab,
      reports: [],
      past_prescription_page: 1,
      total_prescription_pages: 1,
      noMorePastPrescriptionData: false,
      isPastPrescriptionRequestProcessing: false,
      pp_dataLoaded: false,
    }, () => {
      this.pastPresciptions()
    }
    )
  }

  render() {

    const { is_doctor, is_homecare } = this.state;

    return (
      <>
        <HeaderComponent />
        <section
          style={{ background: "#5c5c5c" }}
          className="section-subheader"
        >
          <Container fluid className="container_fluid_custom">
            <Row className="align-items-center">
              <Col>
                <ListGroup horizontal className="subHead-list">
                  <ListGroup.Item className="bg-transparent text-white h5 m-0 p-0 mr-3 border-0">
                    {this.props.location.state.pageTitle.toLowerCase()}
                  </ListGroup.Item>
                  {this.props.location.state.subPageTitle ? <ListGroup.Item className="bg-transparent text-white h5 m-0 p-0 mr-3 pl-3 abc border-top-0 border-bottom-0 border-right-0">
                    {this.props.location.state.subPageTitle}
                  </ListGroup.Item> : ''}
                </ListGroup>
              </Col>
              <Col>
                <div className="h4 m-0 d-flex align-items-center justify-content-end">

                  <button
                    className="btn btn btn-outline-danger btn-worklist px-2  mr-2"
                    onClick={this.backButton}
                  >
                    BACK TO PATIENT
                  </button>
                  <button
                    className="btn btn-outline-danger btn-worklist"
                    style={{
                      backgroundColor: "white",
                      color: "#d24350",
                      fontWeight: 500,
                      borderRadius: "5px",
                    }}
                    onClick={this.handleWorkListNav}
                  >
                    WORKLIST
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="container-fluid custom-container mb-3">
          <div className="row pt-4 mb-0">
            <div className="col-12">
              <h5>Past Prescriptions</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <nav className="progress-notes nav nav-tabs custom-margin" role="tablist">
                {/* {is_doctor && is_homecare ? ( */}
                <a id="controlled-tab-example-tab-doctor" href="#" role="tab" aria-controls="controlled-tab-example-tabpane-doctor"
                  className={`nav-item nav-link ${this.state.activeTab === "virtual" ? "active" : ""} ${is_doctor && is_homecare ? "visible" : "invisible"}`}
                  onClick={(e) => this.handleTabClick(e, 'virtual')}>
                  Vitual Consult
                  </a>
                <a id="controlled-tab-example-tab-nurse" href="#" role="tab" aria-controls="controlled-tab-example-tabpane-nurse"
                  className={`nav-item nav-link ${this.state.activeTab === "homecare" ? "active" : ""} ${is_doctor && is_homecare ? "visible" : "invisible"}`}
                  onClick={(e) => this.handleTabClick(e, 'homecare')}>
                  HomeCare
                  </a>
                {/* ) : ""} */}
              </nav>

              {/* <div className="nav nav-pills">
                <div className="nav-item">
                  <a href="#" className={`py-1 nav-link ${this.state.activeTab === "virtual" ? "active" : ""}`}>
                    Vitual Consult
                  </a>
                </div>
                <div className="nav-item">
                  <a href="#" className={`py-1 nav-link ${this.state.activeTab === "homecare" ? "active" : ""}`}>
                    HomeCare
                  </a>
                </div>
            </div> */}
            </div>
          </div>
          <div className="row scroll_data">
            {this.state.pp_dataLoaded && this.state.cards.length > 0 ? this.state.cards : ""}
          </div>
          <div
            className="col d-flex justify-content-center align-self-center mt-2"
            style={{
              visibility: `${!this.state.pp_dataLoaded || this.state.isPastPrescriptionRequestProcessing ? "visible" : "hidden"}`,
            }}
          >
            {LOADER_RED}
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.documentToggle}
          className={this.state.modalClass}
        >
          <ModalHeader toggle={this.documentToggle}></ModalHeader>
          <ModalBody>
            <div className="img-rotate">
              <i className="ir-left" onClick={HANDLEIMAGEROTATE} name="rotate-left"><img className="rotate-left" src={rotateLeft} /></i>
              <i className="ir-right" onClick={HANDLEIMAGEROTATE} name="rotate-right"><img className="rotate-left" src={rotateRight} /></i>
            </div>
            <div className="img-arrows" style={{ 'display': this.state.showArrow ? 'flex' : 'none' }}>
              <i className="ir-left" onClick={this.handleNextPrev} name="image-left"><img className="image-left" src={prevImgIcon} /></i>
              <i className="ir-right" onClick={this.handleNextPrev} name="image-right"><img className="image-left" src={nextImgIcon} /></i>
            </div>
            <div className="object-wrapper ow-fullheight" >
              {this.state.documentType === 'application/pdf' ?
                <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object> : (
                    <figure className="zoom" style={{ backgroundImage: `url('${this.state.dataFile}')`, backgroundSize: '150%', backgroundRepeat: 'no-repeat' }} id='objdoc1' data-rotate="0" onMouseMove={((e)=>ZOOM_IN(e,this.state.dataFile))}>
                      <object title="Document" data={this.state.dataFile} type={this.state.documentType} data-rotate="0" id="objDoc"></object>
                    </figure>
                )}

            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withRouter(pastVisit);