import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import HeaderComponent from '../header/headerComponent'
import HcsubHeaderComponent from '../sub-header-hc/hcsubHeaderComponent'
import heart from '../../assets/images/ic-heart.svg'
import Moment from "react-moment";
import { Row, Col, Card, } from "react-bootstrap";
import { KARDIA_ECG, LOADER_RED, } from '../../utils/constant';
import axios from "axios";

let _scrollEcgEvent = "";


class Ecg extends React.Component {

    state = {
        kardiaRecordings: [],
        kardiaDataLoaded: false,
        kardiaMrn: this.props.location.state.kardia_mrn,
        subHeaderData: this.props.location.state.subHeaderData,
        pdfData: null,
        activeId: null,
        noMoreData: false,
        isProcessing: false,
        pagination: 1
    }




    componentDidMount() {
        this.handleKardiaData();
        this.setState({
            isProcessing: true
        })
    }

    handleActiveData = (e) => {
        this.setState({
            pdfData: e.target.dataset.pdf,
            activeId: e.target.dataset.id
        })
    }



    handleKardiaData = () => {
        const { kardiaMrn, pagination, kardiaRecordings } = this.state;

        axios.get(KARDIA_ECG({ kardiaMrn, pagination }))
            .then((res) => {
                const data = res.data.recordings;
                this.setState({
                    kardiaRecordings: [...kardiaRecordings, ...data],
                    noMoreData: res.data.pagination.links.next === "" ? true : false,
                    activeId: data[0]?.id,
                    pdfData: data[0]?.kardia_pdf_path,
                    isProcessing: false,
                    kardiaDataLoaded: true
                }, () => {
                    const infitr = document.querySelector(".cp_scroll_loader");
                    if (infitr !== null)
                        infitr.addEventListener("scroll", this.handleScroll, true);
                })
            })
    }


    handleScroll = () => {
        const lastLi = document.querySelector(
            ".cp_scroll_loader > div > div:last-child"
        );
        const scrollWin = document.querySelector(".cp_scroll_loader");
        if (lastLi === null) return;
        let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        let pageOffset = scrollWin.scrollTop + scrollWin.clientHeight + lastLi.clientHeight;
        if (pageOffset > lastLiOffset) {
            this.LoadMore();
        }
    };

    LoadMore = () => {
        const { noMoreData, isProcessing } = this.state;
        if (noMoreData) return;
        if (isProcessing === true) return;
        this.setState(prevState => ({
            isProcessing: true,
            pagination: prevState.pagination + 1
        }), () => this.handleKardiaData())
    }


    render() {
        const { kardiaDataLoaded, pdfData, kardiaRecordings, activeId, isProcessing, subHeaderData } = this.state
        return (
            <div>
                <HeaderComponent />
                <HcsubHeaderComponent data={subHeaderData} />
                <div className="container-fluid custom-container">
                    <div>
                        <div className="row">
                            <div className="col-12 p-0">
                                <h5 className="py-3 mb-0">ECG Readings</h5>
                            </div>
                        </div>

                        {kardiaDataLoaded && kardiaRecordings.length > 0 ? (
                            <div className="row ecg__body p-2">
                                <div className="col-3 p-0">
                                    <aside className="aside-copy  w-100 cp_scroll_loader">
                                        <div>
                                            {/* {copyPrescription_data.map((ele, index) => ( */}
                                            {kardiaRecordings.map((record, i) => (
                                                <div className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
                                                    <div className="ecg-card-bpm d-flex my-2 justify-content-between">
                                                        <div className="ecg-bpm-value d-flex flex-row">
                                                            <img className="mb-1" src={heart} alt="heart-img" />
                                                            <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
                                                            {/* <p className="mb-0"></p> */}
                                                        </div>
                                                        <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
                                                    </div>
                                                    <p>
                                                        <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
                                                            {record.recorded_at}
                                                        </Moment>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col justify-content-center align-self-center"
                                            style={{
                                                display: `${isProcessing ? "flex" : "none"}`,
                                                margin: `${isProcessing ? "30px 0" : ""}`,
                                            }}
                                        >
                                            {LOADER_RED}
                                        </div>
                                    </aside>
                                </div>
                                <div className="col-9 pl-2 pr-0">
                                    <div className="ecg_right bg-white">
                                        <object title="Document" data={pdfData} type='application/pdf' data-rotate="0"></object>
                                    </div>
                                </div>
                            </div>
                        ) : kardiaDataLoaded && kardiaRecordings.length === 0 ? (
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <Card className="mb-3">
                                            <Card.Body className="card-bodycustom card-pastVisit">
                                                <Row className="d-flex" style={{ flexWrap: "nowrap" }}>
                                                    <Col className="d-flex justify-content-center">
                                                        <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-0">
                                                            No ECG Readings
                                                            </Card.Title>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        ) : (
                                    <div className="col justify-content-center align-self-center"
                                        style={{
                                            display: `${!kardiaDataLoaded ? "flex" : "none"}`,
                                            margin: `${!kardiaDataLoaded ? "30px 0" : ""}`,
                                        }}
                                    >
                                        {LOADER_RED}
                                    </div>

                                )}
                    </div>


                </div>

            </div>
        )
    }

}


export default withRouter(Ecg);

// function Ecg(props) {


//     // const kardia_data = props.location.state.kardia_data;
//     const [kardiaRecordings, setKardiaRecordings] = useState([]);
//     const [kardiaDataLoaded, setKardiaDataLoaded] = useState(false);
//     const [kardiaMrn, setKardiaMrn] = useState(props.location.state.kardia_mrn);
//     const [subHeaderData, setSubHeaderData] = useState(props.location.state.subHeaderData)
//     const [pdfData, setPdfData] = useState(null);
//     const [activeId, setActiveId] = useState(null);
//     const [noMoreData, setNoMoreData] = useState(false)
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [pagination, setPagination] = useState(1);

//     const handleActiveData = (e) => {
//         setPdfData(e.target.dataset.pdf);
//         setActiveId(e.target.dataset.id)
//         console.log(activeId)
//     }

//     useEffect(() => {
//         handleKardiaInitialData();
//         setPdfData(kardiaRecordings[0]?.kardia_pdf_path);
//         setActiveId(kardiaRecordings[0]?.id)
//         setIsProcessing(true)
//     }, []);

//     useEffect(() => {
//         handleKardiaInitialData();
//     }, [pagination]);

//     // useEffect((pre) => {
//     //     if (_scrollEcgEvent === "") {
//     //         _scrollEcgEvent = document.querySelector(".cp_scroll_loader");
//     //         console.log(_scrollEcgEvent)
//     //         if (_scrollEcgEvent !== null)
//     //             _scrollEcgEvent.addEventListener("scroll", handleScroll, true);
//     //     }

//     //     return () => {
//     //         if (_scrollEcgEvent !== "" && _scrollEcgEvent !== null)
//     //             _scrollEcgEvent.removeEventListener("scroll", handleScroll, true);
//     //     }
//     // }, [kardiaDataLoaded]);

//     const handleKardiaInitialData = () => {
//         setIsProcessing(true);
//         axios.get(KARDIA_ECG({ kardiaMrn, pagination }))
//             .then(res => {
//                 const data = res.data.recordings;
//                 setKardiaRecordings([...kardiaRecordings, ...data]);
//                 setNoMoreData(res.data.pagination.links.next === "" ? true : false);
//                 setActiveId(data[0]?.id);
//                 setPdfData(data[0]?.kardia_pdf_path);
//                 setIsProcessing(false);
//                 setKardiaDataLoaded(true);
//                 setTimeout(() => {
//                     const infitr = document.querySelector(".cp_scroll_loader");
//                     if (infitr !== null)
//                         infitr.addEventListener("scroll", handleScroll, true);
//                 }, 0);
//             })
//     }

//     const handleScroll = () => {
//         const lastLi = document.querySelector(
//             ".cp_scroll_loader > div > div:last-child"
//         );
//         const scrollWin = document.querySelector(".cp_scroll_loader");
//         if (lastLi === null) return;
//         let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
//         let pageOffset = scrollWin.scrollTop + scrollWin.clientHeight + lastLi.clientHeight;
//         if (pageOffset > lastLiOffset) {
//             LoadMore();
//         }
//     };

//     const LoadMore = () => {
//         debugger
//         if (noMoreData) return;
//         if (isProcessing === true) return;
//         setPagination(pagination + 1);
//     }

//     return (
//         <div>
//             <HeaderComponent />
//             <HcsubHeaderComponent data={subHeaderData} />
//             <div className="container-fluid custom-container">
//                 <div>
//                     <div className="row">
//                         <div className="col-12 p-0">
//                             <h5 className="py-3 mb-0">ECG Readings</h5>
//                         </div>
//                     </div>

//                     {kardiaDataLoaded && kardiaRecordings.length > 0 ? (
//                         <div className="row ecg__body p-2">
//                             <div className="col-3 p-0">
//                                 <aside className="aside-copy  w-100 cp_scroll_loader">
//                                     <div>
//                                         {/* {copyPrescription_data.map((ele, index) => ( */}
//                                         {kardiaRecordings.map((record, i) => (
//                                             <>
//                                                 <div className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
//                                                     <div className="ecg-card-bpm d-flex mb-3 justify-content-between">
//                                                         <div className="ecg-bpm-value d-flex flex-row">
//                                                             <img className="mb-1" src={heart} alt="heart-img" />
//                                                             <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
//                                                             {/* <p className="mb-0"></p> */}
//                                                         </div>
//                                                         <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
//                                                     </div>
//                                                     <p>
//                                                         <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
//                                                             {record.recorded_at}
//                                                         </Moment>
//                                                     </p>
//                                                 </div>
//                                                 <div className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
//                                                     <div className="ecg-card-bpm d-flex mb-3 justify-content-between">
//                                                         <div className="ecg-bpm-value d-flex flex-row">
//                                                             <img className="mb-1" src={heart} alt="heart-img" />
//                                                             <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
//                                                             {/* <p className="mb-0"></p> */}
//                                                         </div>
//                                                         <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
//                                                     </div>
//                                                     <p>
//                                                         <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
//                                                             {record.recorded_at}
//                                                         </Moment>
//                                                     </p>
//                                                 </div>
//                                                 <div className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
//                                                     <div className="ecg-card-bpm d-flex mb-3 justify-content-between">
//                                                         <div className="ecg-bpm-value d-flex flex-row">
//                                                             <img className="mb-1" src={heart} alt="heart-img" />
//                                                             <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
//                                                             {/* <p className="mb-0"></p> */}
//                                                         </div>
//                                                         <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
//                                                     </div>
//                                                     <p>
//                                                         <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
//                                                             {record.recorded_at}
//                                                         </Moment>
//                                                     </p>
//                                                 </div>
//                                                 <div className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
//                                                     <div className="ecg-card-bpm d-flex mb-3 justify-content-between">
//                                                         <div className="ecg-bpm-value d-flex flex-row">
//                                                             <img className="mb-1" src={heart} alt="heart-img" />
//                                                             <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
//                                                             {/* <p className="mb-0"></p> */}
//                                                         </div>
//                                                         <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
//                                                     </div>
//                                                     <p>
//                                                         <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
//                                                             {record.recorded_at}
//                                                         </Moment>
//                                                     </p>
//                                                 </div>
//                                                 <div className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
//                                                     <div className="ecg-card-bpm d-flex mb-3 justify-content-between">
//                                                         <div className="ecg-bpm-value d-flex flex-row">
//                                                             <img className="mb-1" src={heart} alt="heart-img" />
//                                                             <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
//                                                             {/* <p className="mb-0"></p> */}
//                                                         </div>
//                                                         <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
//                                                     </div>
//                                                     <p>
//                                                         <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
//                                                             {record.recorded_at}
//                                                         </Moment>
//                                                     </p>
//                                                 </div>
//                                                 <div className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
//                                                     <div className="ecg-card-bpm d-flex mb-3 justify-content-between">
//                                                         <div className="ecg-bpm-value d-flex flex-row">
//                                                             <img className="mb-1" src={heart} alt="heart-img" />
//                                                             <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
//                                                             {/* <p className="mb-0"></p> */}
//                                                         </div>
//                                                         <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
//                                                     </div>
//                                                     <p>
//                                                         <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
//                                                             {record.recorded_at}
//                                                         </Moment>
//                                                     </p>
//                                                 </div>
//                                             </>
//                                         ))}
//                                     </div>
//                                     <div className="col justify-content-center align-self-center"
//                                         style={{
//                                             display: `${isProcessing ? "flex" : "none"}`,
//                                             margin: `${isProcessing ? "30px 0" : ""}`,
//                                         }}
//                                     >
//                                         {LOADER_RED}
//                                     </div>
//                                 </aside>
//                             </div>
//                             <div className="col-9 pl-2 pr-0">
//                                 <div className="ecg_right bg-white">
//                                     <object title="Document" data={pdfData} type='application/pdf' data-rotate="0"></object>
//                                 </div>
//                             </div>
//                         </div>
//                     ) : kardiaDataLoaded && kardiaRecordings.length === 0 ? (
//                         <div className="row">
//                             <div className="col-12">
//                                 <div>
//                                     <Card className="mb-3">
//                                         <Card.Body className="card-bodycustom card-pastVisit">
//                                             <Row className="d-flex" style={{ flexWrap: "nowrap" }}>
//                                                 <Col className="d-flex justify-content-center">
//                                                     <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-0">
//                                                         No ECG Readings
//                                                 </Card.Title>
//                                                 </Col>
//                                             </Row>
//                                         </Card.Body>
//                                     </Card>
//                                 </div>
//                             </div>
//                         </div>
//                     ) : (
//                                 <div className="col justify-content-center align-self-center"
//                                     style={{
//                                         display: `${!kardiaDataLoaded ? "flex" : "none"}`,
//                                         margin: `${!kardiaDataLoaded ? "30px 0" : ""}`,
//                                     }}
//                                 >
//                                     {LOADER_RED}
//                                 </div>

//                             )}
//                 </div>


//             </div>

//         </div>
//     )
// }




