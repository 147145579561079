/**
 *  Private Route 
 * 
 *  @author Mahesh Hegde <mahesh&#64;innocirc.com>
 *  @version 1.0.0 (22-May-2019)
 *  @copyright InnoCirc Ventures Pvt. Ltd.
 * 
 *  @description
 *  Private Route for VC App.
 * 
 */

import React from 'react';
import { 
    Route, 
    Redirect 
} from 'react-router';
import LS_SERVICE from "./localStorage";

const PrivateRoute = ({component: Component, isPaired, ...rest}) => {
    return (
        <Route 
            {...rest} 
            render={ 
                (props) => (LS_SERVICE.get("is_authenticated") ? <Component {...props} /> : <Redirect to="/" />)
            }
        />
    );
};

export default PrivateRoute;