import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Toast } from "react-bootstrap";

function ToastComponent(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.show === true) {
      setShow(true);
    }
  }, [props.show, setShow]);

  const handleClose = (value) => {
    setShow(value);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "999"
      }}
    >
      <Toast
        onClose={() => handleClose(false)}
        show={show}
        delay={3000}
        autohide
        className={`text-white font-weight-bold ${props.successClass}`}
      >
        <Toast.Body>{props.message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default withRouter(ToastComponent);
