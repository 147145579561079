import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import {
  LOGOUT_URL,
  HOMECARE_ALERT_DROPDOWN,
  GETGENDER, GETDOB,
  HOMECARE_VITAL_ALERT_DROPDOWN,
  HOMECARE_VITAL_ALERT_ACKNOWLEDGE,
  BOOKING_LIST_CHAT
} from "../../utils/constant";
import LOGO from "../../assets/images/logo-max.png";
import DR_DEFAULT_IMG from "../../assets/images/user.png";
import { Navbar, NavDropdown, Image, Row, Col } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import LS_SERVICE from "../../utils/localStorage";
import ChatIcon from "../../assets/images/ic-chat.svg";
import ToastComponent from "../toast/toast";
import Moment from "react-moment";
import alertTone from '../../assets/audio/alert-tone.mp3';
import vitalAlertTone from '../../assets/audio/vital-alert-tone.mp3';
import { pubnubService$ } from '../../utils/pubnub-sharing';
import next from '../../assets/images/ic-next.svg'

let alertApiTimer = "";
let vitalAlertApiTimer = "";
let alertToneCount = 0;
let vitalToneCount = 0;

class HeaderComponent extends Component {

  state = {
    doctor: [],
    authorization_token: '',
    doctor_name: '',
    name: '',
    doctor_id: null,
    is_doctor: false,
    doctor_profile_picture: '',
    isProfileDropOpen: false,
    toastSuccessClass: "bg-danger",
    toast: false,
    toastmsg: "",
    alerts: [],
    vitalAlerts: [],
    pubNubAlerts: [],
    pubNubTotalCounts: 0,
    pubNubAlertToggle: false
  };

  constructor(props) {
    super(props);
    if (!LS_SERVICE.get("doctor") || !LS_SERVICE.get("authorization")) {
      LS_SERVICE.clear();
      props.history.push("/");
    }
  }

  UNSAFE_componentWillMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    this.setState({
      doctor: LS_SERVICE.get("doctor"),
      authorization_token: LS_SERVICE.get("authorization"),
      doctor_name: LS_SERVICE.get("doctor").name !== null ? LS_SERVICE.get("doctor").name : LS_SERVICE.get("doctor").first_name,
      name: LS_SERVICE.get("doctor").first_name,
      doctor_profile_picture: LS_SERVICE.get("doctor").profile_picture,
      doctor_id: LS_SERVICE.get("doctor").id,
      is_doctor: LS_SERVICE.get('is_doctor')
    })
  }

  componentDidMount() {

    const { is_doctor } = this.state;

    this.alertApiFunction();
    this.alertVitalsGetApi();
    // if (is_doctor) {
    //   this.bookingListGetApi();
    // }

    pubnubService$.pubnub_observe().subscribe(success => {
      if (success.pubnub_message_counts) {

        let { pubNubAlerts, pubNubTotalCounts } = this.state;

        pubNubTotalCounts = 0;

        pubNubAlerts.forEach(e => {
          e.unread_count = success.pubnub_message_counts[e.booking_id];
          pubNubTotalCounts += +e.unread_count;
        })

        this.setState({
          pubNubAlerts,
          pubNubTotalCounts
        })
      }

      // if (success.pubnub_notification_update) {
      //   this.bookingListGetApi()
      // }
    })
  }

  componentWillUnmount() {
    // if (!LS_SERVICE.get('is_authenticated')) {
    clearTimeout(alertApiTimer);
    alertApiTimer = "";
    clearTimeout(vitalAlertApiTimer);
    vitalAlertApiTimer = "";
    // }
  }

  resetToastState = (cb = "") => {
    this.setState({
      toastSuccessClass: "bg-danger",
      toast: false,
      toastmsg: "",
    }, () => { if (cb !== "") cb() });
  };

  alertVitalSave = (e) => {

    e.preventDefault();
    e.stopPropagation();

    const { is_doctor } = this.state;

    const target = e.target

    const PARAMS = {
      clear_alert: 1,
      alert_id: target.dataset.alertid
    }

    axios.post(HOMECARE_VITAL_ALERT_ACKNOWLEDGE({ is_doctor }), PARAMS)
      .then((res) => {
        vitalToneCount -= 1;  //for tone
        this.alertVitalsGetApi();
        this.resetToastState(() => this.setState({
          toastSuccessClass: "bg-success",
          toast: true,
          toastmsg: res.data.message,
        }))
      })
      .catch((error) => {
        this.resetToastState(() => this.setState({
          toastSuccessClass: "bg-success",
          toast: true,
          toastmsg: error.response.data.message,
        }))
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      })

  }

  alertVitalsGetApi = _ => {

    const { is_doctor, doctor_id } = this.state;

    const id = doctor_id;

    const hospital_id = LS_SERVICE.get('active_hospital').id;

    axios.get(HOMECARE_VITAL_ALERT_DROPDOWN({ is_doctor, id, hospital_id }))
      .then((response) => {

        let { vitalAlerts } = this.state;
        vitalAlerts = response.data.alerts;

        this.setState({
          vitalAlerts
        }, () => {
          this.VitalAlertTone();
          vitalAlertApiTimer = setTimeout(() => { this.alertVitalsGetApi() }, 15000)
        });
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        } else {
          this.alertVitalsGetApi()
        }
      });
  }

  alertApiFunction = _ => {

    const { is_doctor, doctor_id } = this.state;

    const id = doctor_id;

    axios
      .get(HOMECARE_ALERT_DROPDOWN({ is_doctor, id }))
      .then((response) => {

        let { alerts } = this.state;
        alerts = response.data.alerts;

        this.setState({
          alerts
        }, () => {
          this.alertTone();
          alertApiTimer = setTimeout(() => { this.alertApiFunction() }, 15000)
        });
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        } else {
          this.alertApiFunction()
        }
      });
  }

  // bookingListGetApi = () => {
  //   const { is_doctor } = this.state;
  //   axios.get(BOOKING_LIST_CHAT({ is_doctor }))
  //     .then(success => {
  //       pubnubService$.pubnub_active_chats({ pubnubActiveChatData: success.data.chat_bookings });

  //       success.data.chat_bookings.map(e => e['unread_count'] = 0);

  //       this.setState({
  //         pubNubAlerts: success.data.chat_bookings
  //       })

  //       setTimeout(() => {
  //         this.bookingListGetApi();
  //       }, 15000);
  //     })
  //     .catch(error => {
  //       if (error && error.response && error.response.status === 401) {
  //         LS_SERVICE.clear();
  //         this.props.history.push("/");
  //       }
  //     })
  // }

  navLogoClickHandler = (e) => {
    e.preventDefault();
    const isLoggedIn = LS_SERVICE.has("uid");
    if (isLoggedIn) this.props.history.push("/patient-lists");
    else this.props.history.push("/");
  };

  handleProfile = () => {
    this.props.history.push("/profile")
  }

  handleLogout = () => {
    const callLogoutMsg = "You cannot logout as a video call is going on, end the call to logout from application!";
    if (document.getElementById('endconsult_call_btn') !== null || (document.getElementById('floating_vc_dialog') !== null &&
      !document.getElementById('floating_vc_dialog').classList.contains('hidden'))) {
      this.setState({
        toastSuccessClass: "bg-danger",
        toast: false,
        toastmsg: ""
      }, () => {
        this.setState({
          toastSuccessClass: "bg-danger",
          toast: true,
          toastmsg: callLogoutMsg
        });

        setTimeout(() => {
          this.setState({
            toast: false,
            toastmsg: ""
          });
        }, 5000);
      });
      return false;
    }

    axios
      .get(LOGOUT_URL)
      .then((response) => {
        LS_SERVICE.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  };

  handleProfileDropdown = () => {
    this.setState({
      isProfileDropOpen: !this.state.isProfileDropOpen
    })
  }

  handleAlertClick = (booking_id) => {
    this.props.history.push({
      pathname: "/hc-consult",
      state: { booking_id: booking_id, homeCare: 1 },
    });
  }

  alertTone = () => {
    const { alerts } = this.state
    let audio = new Audio();
    audio.src = alertTone;

    if (alerts.length != alertToneCount) {
      audio.play();
      alertToneCount = alerts.length;
    }
  }

  VitalAlertTone = () => {
    const { vitalAlerts } = this.state
    let audio = new Audio();
    audio.src = vitalAlertTone;

    if (vitalAlerts.length != vitalToneCount) {
      audio.play();
      vitalToneCount = vitalAlerts.length;
    }
  }

  handlePubNubAlertClick = (el, alert) => {

    el.preventDefault();

    const data = {
      id: alert.booking_id,
      patient: {
        dob: alert.dob,
        first_name: alert.first_name,
        gender: alert.gender,
        last_name: alert.last_name,
        uhid: null
      },
    };

    pubnubService$.pubNubBooking_data({ data, show: true, alert: true });

    this.setState({
      pubNubAlertToggle: !this.state.pubNubAlertToggle
    })
  }

  render() {

    const { alerts, vitalAlerts, pubNubAlerts, pubNubTotalCounts } = this.state;

    return (
      <>
        <Navbar bg="light" className="justify-content-between">
          <div className="left-align">
            <Navbar.Brand href="">
              <Row className="align-items-center">
                <Col className="pr-0 header-logo">
                  <span onClick={(e) => this.navLogoClickHandler(e)}>
                    <img
                      src={LOGO}
                      alt="myhealthcare-logo"
                      className="mhc-logoheader"
                    />
                  </span>
                </Col>
                <Col>
                  <p className="h6 p-0 m-0 mb-2 vc-name">
                    MyHealthcare Clinical Platform
                </p>
                </Col>
              </Row>
            </Navbar.Brand>
          </div>
          <div className="right-align">
            <Row className="align-items-center">

              {pubNubAlerts.length > 0 ? (
                <Dropdown className="pubnub-alerts col">
                  <Dropdown.Toggle
                    variant="link"
                  >
                    <img src={ChatIcon} alt="chatIcon" />
                    {pubNubTotalCounts
                      > 0 ? (
                        <span className="alert-notif">
                          {pubNubTotalCounts}
                        </span>
                      ) : ""}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    align="right">
                    <Dropdown.Header className="alert-modal vitals modal-header">
                      <p className=" modal-title font-weight-bold text-white h6">Chat with Patients</p>
                    </Dropdown.Header>
                    <div className="navdrop_itemwrapper pubnub">
                      {pubNubAlerts ? pubNubAlerts.map((e, i) => (
                        <Dropdown.Item key={i} className="px-3 py-2 border-bottom" onClick={(el) => this.handlePubNubAlertClick(el, e)}>
                          <Row>
                            <Col className="d-flex justify-content-between">
                              <div className="chat_row_left">
                                <p className="p-o m-0 font-weight-bold ">{e.patient_name}</p>
                                <p className="p-o m-0  d-flex">
                                  <span>Appointments : &nbsp;</span>
                                  <Moment format="D MMM, Y | h:mm A" parse="YYYY-MM-DD HH:mm" className="text-dark">
                                    {e.booking_date} {e.booking_time}
                                  </Moment>
                                </p>
                              </div>
                              <div className="chat_row_right d-flex  align-items-center ">
                                <div className={`${e.unread_count === 0 ? 'd-none' : ''}`}>{e.unread_count}</div>
                                <img src={next} alt="next-arrow" />
                              </div>

                            </Col>
                          </Row>
                        </Dropdown.Item>
                      )) : ""}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : ""}

              {vitalAlerts.length > 0 ?
                (<Col className="pr-0 alert-wrapper mr-5">
                  <span className="alert-notif">
                    {vitalAlerts.length}
                  </span>
                  <NavDropdown
                    className="box-alert vitals p-0 mt-3"
                    title='VITALS ALERTS'
                    id="alertVitals-healthdropdown"
                  >
                    <NavDropdown.Header className="alert-modal vitals modal-header">
                      <p className=" modal-title font-weight-bold text-white h6">VITALS ALERTS <span className="h5"> {vitalAlerts.length}</span></p>
                    </NavDropdown.Header>
                    <div className="navdrop_itemwrapper">
                      {vitalAlerts.length > 0 ? vitalAlerts.slice(0).reverse().map((e, i) => (
                        <NavDropdown.Item key={i} className="px-3 py-2 border-bottom" onClick={() => this.handleAlertClick(e.test_booking_id)}>
                          <Row>
                            <Col md={4}>
                              <p className="p-o  m-0 ">{e.patient.first_name + ' ' + e.patient.last_name}</p>
                              <p className="p-o  m-0 text-grey">{GETDOB(e.patient.dob)}, {GETGENDER(e.patient.gender)} {e.patient.uhid !== null ? '| ' + e.patient.uhid : ''} {e.patient.relation !== null ? '| ' + e.patient.relation : ''}
                              </p>
                            </Col>
                            <Col md={4}>
                              <p className="float-right pt-3">
                                <Moment className="float-right" format="D MMM, Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {e.alert_date} {e.alert_time}
                                </Moment>
                              </p>
                            </Col>
                            <Col md={4}>
                              <button className="btn btn-outline-danger custom font-weight-bold float-right py-0 mt-3" data-alertid={e.alert_id}
                                onClick={(eve) => this.alertVitalSave(eve)}>
                                ACKNOWLEDGE
                              </button>
                            </Col>
                          </Row>
                        </NavDropdown.Item>
                      )) : ""}
                    </div>
                  </NavDropdown>
                </Col>
                ) : ""}

              {alerts.length > 0 ?
                // {patient_vitals !== 0 ? (): }
                (<Col className="pr-0 alert-wrapper">
                  <span className="alert-notif">
                    {alerts.length}
                  </span>
                  <NavDropdown
                    className="box-alert p-0 mt-3"
                    title='PATIENT ALERTS'
                    id="alert-healthdropdown"
                  >
                    <NavDropdown.Header className="alert-modal modal-header">
                      <p className=" modal-title font-weight-bold h6">PATIENT ALERTS <span className="h5"> {alerts.length}</span></p>
                    </NavDropdown.Header>
                    <div className="navdrop_itemwrapper">
                      {alerts.length > 0 ? alerts.map((e, i) => (
                        <NavDropdown.Item key={i} className="px-3 py-2 border-bottom" onClick={() => this.handleAlertClick(e.test_booking_id)}>
                          <Row>
                            <Col md={6}>
                              <p className="p-o  m-0 ">{e.patient.first_name + ' ' + e.patient.last_name}</p>
                              <p className="p-o  m-0 text-grey">{GETDOB(e.patient.dob)}, {GETGENDER(e.patient.gender)} {'| ' + e.patient.uhid}
                                {/* | B+ */}
                              </p>
                            </Col>
                            <Col md={6}>
                              <p className="float-right pt-3">
                                <Moment className="float-right" format="D MMM, Y | h:mm A" parse="YYYY-MM-DD HH:mm">
                                  {e.alert_date} {e.alert_time}
                                </Moment>
                              </p>
                            </Col>
                          </Row>

                        </NavDropdown.Item>
                      )) : ""}
                    </div>
                  </NavDropdown>
                </Col>
                ) : ""}
              <Col className="pr-0 pl-5 ml-5">
                <NavDropdown
                  className="text-white pull-right"
                  title={this.state.doctor_name}
                  id="nav-healthdropdown"
                >
                  <NavDropdown.Item onClick={this.handleProfile}>
                    View Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={this.handleLogout}>
                    Logout
                </NavDropdown.Item>
                </NavDropdown>
              </Col>
              <Col className="pl-0 header-doctorimg">
                <Image
                  src={
                    this.state.doctor_profile_picture !== ""
                      ? this.state.doctor_profile_picture
                      : DR_DEFAULT_IMG
                  }
                  roundedCircle
                  style={{ height: "50px" }}
                />
              </Col>
              {/* <Col className="header-powered-col">
              <div className='d-flex align-items-center justify-content-between header-powered'>
                <p>powered by</p>
                <img src={poweredLogo} />
              </div>
            </Col> */}
            </Row>
          </div>
        </Navbar>

        <ToastComponent
          successClassName={this.state.toastSuccessClass}
          show={this.state.toast}
          message={this.state.toastmsg}
        />
      </>
    );
  }
}

export default withRouter(HeaderComponent);
