import React, { Component } from 'react';
import HeaderComponent from '../header/headerComponent';
import {
    LIST_ALLERGIES, LIST_CONDITIONS,
    BOOKINGTIME24TO12, UHID, LOADER_RED
} from "../../utils/constant";
import allergyDanger from "../../assets/images/allergies-danger.png";
import allergyWarning from "../../assets/images/allergies-warning.png";
import allergyPrimary from "../../assets/images/allergies-primary.png";
import LS_SERVICE from "../../utils/localStorage";
import axios from 'axios';
import SubHeaderComponent from '../sub-header/subHeaderComponent';

const ALLERGY_TYPES = { 0: "Drug", 1: "Food", 2: "Others" };
const ALLERGY_INTENSITY_OBJECT = { 0: "Low", 1: "Moderate", 2: "Severe" };
const ALLERGY_OBSERVED_BY = { 0: "Observed by Hospital", 1: "Informed by Patient" };
const EXISTING_CONDITION_DURATION_TYPE = { 0: "Days", 1: "Weeks", 2: "Months", 3: "Years" };
const EXISTING_CONDITION_ON_MEDITATION_TYPE = { 1: "Yes", 0: "No" };

export default class Allergies_vc extends Component {

    state = {
        addAllergyPopup: false,
        addConditionPopup: false,
        allergiesTab: "Food",
        knownAllergiesData: [],
        existingConditionData: [],
        currentMeditation: "",
        pageDataLoaded: false,
        subHeaderData: null,
        active_route: "",
        is_doctor: false,
        toastSuccessClass: "bg-danger",
        toast: false,
        toastmsg: "",
        allergy_observedby: "0",
        allergy_type: "0",
        allergypage: 1,
        allergyValArray: [],
        keyword: "",
        allergy_value: null,
        selectedAllergyId: "",
        allergyIntensity: "0",
        booking_id: null,
        selectedOption: "",
        existing_condition_text: "",
        existing_condition_duration: "",
        existing_condition_id: "",
        existing_condition_id_value: "",
        existing_condition_days: "0",
        existing_condition_medication_name: "",
        existing_condition_on_meditation: "1",
        existing_medication_name: "",
        existing_condition_dropdown_values: [],
        inputEditHasData: false,
        allergy_name: '',
        allergy_refId: 0,
        allergy_comment: " ", //we don't have an option to edit, but in update api params it's required
        allergy_reaction: "",
        patient: {},
        patient_id: 0
    }

    constructor(props) {
        super(props);
        if (props.location.state === undefined) {
            props.history.push("/patient-list");
            return;
        }
    }

    handleProgressBtn = () => {
        this.props.history.push("/progress-notes");
    }

    UNSAFE_componentWillMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("authorization");
        this.setState({
            is_doctor: LS_SERVICE.get('is_doctor'),
            patient: JSON.parse(this.props.location.state.patient),
            booking_id: this.props.location.state.booking_id,
            patient_id: this.props.location.state.patient_id
        })
    }

    componentDidMount() {
        // const fields = $(".emrfrm .form-control");
        // fields.each((i, e) => {
        //     JQUERYLABELS(e);
        // });

        // $(document).on("focusin", ".emrfrm .form-control", function () {
        //     JQUERYLABELS(this);
        // });

        const { patient_id } = this.state;

        const KNOWN_ALLERGIES = axios.get(LIST_ALLERGIES(patient_id));
        const EXISTING_CONDITIONS = axios.get(LIST_CONDITIONS(patient_id));

        Promise.all([KNOWN_ALLERGIES, EXISTING_CONDITIONS])
            .then((response) => {
                let { knownAllergiesData, existingConditionData } = this.state;
                knownAllergiesData = response[0].data.known_allergies;
                existingConditionData = response[1].data.pre_existing_condition_details;

                knownAllergiesData = [...knownAllergiesData.drug, ...knownAllergiesData.environment, ...knownAllergiesData.food];

                this.setState({
                    knownAllergiesData,
                    existingConditionData,
                    pageDataLoaded: true
                })
            }).catch(error => {
                this.setState({
                    pageDataLoaded: true
                })
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            })
    }




    handleAllergyTabs = (e, allergy_type) => {
        if (e !== null)
            e.preventDefault();

        this.setState({
            allergy_type,
            allergypage: 1,
            allergy_value: null,
            allergy_reaction: "",
            allergyIntensity: "0",
            allergy_observedby: "0"
        })
    }



    render() {

        const { pageDataLoaded, knownAllergiesData, existingConditionData } = this.state;

        const foodAllergy = [];
        if (knownAllergiesData.length > 0) {
            knownAllergiesData.forEach((value, index) => {
                const IMGSRC = value.intensity.id === 0 ? allergyPrimary : value.intensity.id === 1 ? allergyWarning : allergyDanger;
                foodAllergy.push(
                    <div className="item-list mb-3 border-bottom" key={index}>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-custom-allergies mb-0">{ALLERGY_TYPES[value.type]}</h5>
                        </div>
                        <div className="item_description py-2">
                            <div className="item_name d-flex justify-content-between">
                                <p className="text-allergies-type">{value.name}</p>
                                <p className={value.source.id ? "text-danger" : "text-success"}>{value.source.value}</p>
                            </div>
                            <div className="item_reaction d-flex justify-content-between">
                                <div className="d-flex flex-row">
                                    <p className="mr-3">Reaction</p>
                                    <p className="text-dark reaction-text ml-5"><strong>{value.reaction}</strong></p>
                                </div>
                                <p><img src={IMGSRC} /></p>
                            </div>
                            {value.comments !== null ? (
                                <div className="item_comments d-flex justify-content-start">
                                    <p>Comments</p>
                                    <p className="text-dark word-wrap ml-5">{value.comments !== null ? value.comments : "No Comments"}</p>
                                </div>
                            ) : ""}
                        </div>
                    </div>
                )
            })
        } else {
            foodAllergy.push(
                <div className="item-list mb-5" key="1">
                    <h5 className="mb-0 w-100 text-center mt-5">No Known Allergies</h5>
                </div>
            )
        }

        const existingDataList = [];
        if (existingConditionData.length > 0) {
            existingConditionData.forEach((val, index) => {
                existingDataList.push(
                    <div className="item-list mb-3" key={index}>
                        <div className="d-flex justify-content-between">
                            <h6 className="mb-0">{val.condition_name}</h6>
                        </div>
                        <div className="item_description pb-2 pt-1">
                            <p><span>Duration</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.duration} {val.duration_type.value}</p>
                            <p><span>On Medication</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.on_medication.value}</p>
                            {val.medicine_name !== null ? (
                                <p><span>Medicine Name</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.medicine_name}</p>
                            ) : ""}
                            {val.comments !== null ? (
                                <p className="word-wrap-all"><span>Comments</span>:&nbsp;&nbsp;&nbsp;&nbsp;{val.comments}</p>
                            ) : ""}
                        </div>
                    </div>
                )
            })
        } else {
            existingDataList.push(
                <div className="item-list mb-5" key="1">
                    <h5 className="mb-0 w-100 text-center mt-5">No Known Existing Conditions</h5>
                </div>
            )
        }

        return (
            <>
                <HeaderComponent />
                <SubHeaderComponent
                    pageTitle={`${this.state.patient.name} ${this.state.patient.dob !== ""
                        ? this.state.patient.dob + " Yrs,"
                        : ""
                        } ${this.state.patient.gender}`}
                    subPageTitle={UHID(this.state.patient.uhid) !== "" ? 'UHID ' + this.state.patient.uhid : ''}
                    subPageTitle2={'Appointment Time: ' + BOOKINGTIME24TO12(this.state.patient.booking_time)}
                    subPageTitle3={'M : ' + (this.state.patient.number)}
                    PrescriptionBtn={"yes"}
                    E_PrescriptionBtn={LS_SERVICE.has('hospitals') && LS_SERVICE.get('hospitals')[0].id === 1 ? "yes" : "no"}
                    PL={this.props.location.state.PL}
                    btn_name={"Past VC Prescriptions"}
                    patient_id={this.state.patient.id}
                    booking_id={this.state.booking_id}
                    booking_code={this.props.location.state.booking_code}
                    patient = {JSON.stringify(this.state.patient)}
                    ecg_btn={"yes"}
                    allergies_btn={"yes"}
                    backToPatient_btn={"yes"} />
                <div className="container-fluid custom-container">
                    <div className="row m-0">
                        {pageDataLoaded ? (
                            <>
                                <div className={`col-6 p-0`}>
                                    <div className="allergies-col p-0">
                                        <h5 className="known-allergies-h5">KNOWN ALLERGIES</h5>
                                        <div className="known-allergies-data">
                                            {foodAllergy}
                                        </div>
                                        <div className="d-flex btn_allergies justify-content-around severity-label mb-0">
                                            <p>Severity</p>
                                            <p><img className="mb-1 mr-2 allergies-caution-img" src={allergyPrimary} />Low</p>
                                            <p><img className="mb-1 mr-2 allergies-caution-img" src={allergyWarning} />Medium</p>
                                            <p><img className="mb-1 mr-2 allergies-caution-img" src={allergyDanger} />High</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-6 p-0`}>
                                    <div className="allergies-col existing-con p-0">
                                        <h5 className="known-allergies-h5">EXISTING CONDITIONS</h5>
                                        <div className="known-allergies-data">
                                            {existingDataList}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                                <div
                                    className="col d-flex justify-content-center align-self-center mt-5"
                                    style={{
                                        visibility: `${!this.state.notesDataLoaded ? "visible" : "hidden"
                                            }`,
                                    }}
                                >
                                    {LOADER_RED}
                                </div>
                            )}
                    </div>
                </div>

            </>
        )
    }
}
