import React, { Component } from "react";
import { Container, Image, Col } from "react-bootstrap";
import edit from "../../assets/images/ic-edit.png";
import HeaderComponent from "../header/headerComponent";
import SubHeaderComponent from "../sub-header/subHeaderComponent";
import axios from "axios";
import LS_SERVICE from "../../utils/localStorage";
import { API_BASE_URL3, LOADER_RED, GETGENDER, GETDOB, PROFILE } from "../../utils/constant";
import { withRouter } from "react-router-dom";

class Profile extends Component {
  state = {
    doctor_details: {
      name: null,
      profile_picture: null,
      dob: null,
      designation: null,
      specialities: null,
      experience: null,
      doctor_registration_number: null,
      woodlands_mobile: null,
      woodlands_email: null,
    },
    qualification: "",
    profile_text: "",
    profile_picture: "",
    dataLoaded: false,
    gender: {
      id: null,
      value: null,
    },
    is_doctor: false
  };

  constructor(props) {
    super(props);
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get(
      "authorization"
    );

    this.state.is_doctor = LS_SERVICE.get('is_doctor');

    axios
      .get(PROFILE({ is_doctor: this.state.is_doctor, id: LS_SERVICE.get("doctor").id }))
      .then((Response) => {
        this.setState({
          doctor_details: Response.data.doctor,
          qualification: Response.data.doctor.qualification.replace(
            /<[^>]+>/g,
            ""
          ),
          profile_text: Response.data.doctor.profile_text
            ? Response.data.doctor.profile_text
            : "",
          profile_picture: Response.data.doctor.profile_picture
            ? Response.data.doctor.profile_picture
            : "",
          dataLoaded: true,
          gender: {
            id: Response.data.doctor.gender.id,
            value: Response.data.doctor.gender.value,
          }
        });
        var doctor = LS_SERVICE.get("doctor");
        doctor.profile_picture = this.state.profile_picture;
        LS_SERVICE.set("doctor", doctor);
        document
          .getElementById("doctor-profile-picture")
          .setAttribute("src", doctor.profile_picture);
      })
      .catch((error) => {
        this.setState({
          dataLoaded: true,
        });
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  }

  handleEdit = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/edit",
      state: { doctor_details: this.state },
    });
  };

  render() {
    return (
      <>
        <HeaderComponent />
        <SubHeaderComponent pageTitle={"My profile"} />
        <Container fluid className="custom-container">
          <div className="row profile" style={{
            display: `${this.state.dataLoaded ? "flex" : "none"}`,
          }}>
            <Col className="d-inline-flex justify-content-end align-items-start">
              <div className="profile-picwrap text-center d-flex flex-column align-self-start">
                <div className="pc-top">
                  <Image
                    alt="doc-image"
                    className="profile-pic"
                    src={this.state.doctor_details.profile_picture}
                    roundedCircle
                  />
                </div>
                <p className="m-0 mt-3">
                  {GETDOB(this.state.doctor_details.dob)} {GETGENDER(this.state.gender.value)}
                </p>
              </div>
              <div className="profile-details ml-4">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <p className="profile-name">
                      {this.state.doctor_details.name}
                    </p>
                    <p className="profile-des">{this.state.qualification}</p>
                  </div>
                  <div className="profile-edit-btn">
                    <a href="/edit" className="edit" onClick={this.handleEdit}>
                      <img alt="edit"
                        src={edit}
                      />
                      Edit
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-7 col-sm-5 profile-sub">
                    <p>
                      <span className="prfl-sub-left">Designation</span> :{" "}
                      <span className="prfl-sub-right">
                        {this.state.doctor_details.designation}
                      </span>
                    </p>
                    <p>
                      <span className="prfl-sub-left">Speciality</span> :{" "}
                      <span className="prfl-sub-right">
                        {this.state.doctor_details.specialities}
                      </span>
                    </p>
                    <p>
                      <span className="prfl-sub-left">Experience</span> :{" "}
                      <span className="prfl-sub-right">
                        {this.state.doctor_details.experience} Years
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-5 col-sm-5 float-left pl-5">
                    <p>
                      <span className="prfl-sub-left">Registration</span> :
                      <span className="prfl-sub-right">
                        {this.state.doctor_details.doctor_registration_number}
                      </span>
                    </p>
                    <p>
                      <span className="prfl-sub-left">Mobile</span> :
                      <span className="prfl-sub-right">
                        +91-{this.state.doctor_details.woodlands_mobile}
                      </span>
                    </p>
                    <p>
                      <span className="prfl-sub-left">Email</span> :
                      <span className="prfl-sub-right">
                        {this.state.doctor_details.woodlands_email}
                      </span>
                    </p>
                  </div>
                </div>
                {/* <hr className="hr-profile"></hr> */}
                {this.state.profile_text !== "" ? (
                  <div className="row">
                    <Col>
                      <div className="profile-summary">
                        <p>
                          <b>Profile</b>
                        </p>
                        {this.state.profile_text}
                      </div>
                    </Col>
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </Col>
          </div>
          <div
            className="col d-flex justify-content-center align-self-center mt-5"
            style={{
              visibility: `${!this.state.dataLoaded ? "visible" : "hidden"}`,
            }}
          >
            {LOADER_RED}
          </div>
        </Container>
      </>
    );
  }
}

export default withRouter(Profile);
