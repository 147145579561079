import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import HeaderComponent from '../header/headerComponent'
import HcsubHeaderComponent from '../sub-header-hc/hcsubHeaderComponent'
import heart from '../../assets/images/ic-heart.svg'
import Moment from "react-moment";
import { Row, Col, Card, } from "react-bootstrap";
import { KARDIA_ECG, LOADER_RED, UHID, BOOKINGTIME24TO12, } from '../../utils/constant';
import axios from "axios";
import SubHeaderComponent from '../sub-header/subHeaderComponent';
import LS_SERVICE from "../../utils/localStorage";

let _scrollEcgEvent = "";


class EcgVc extends React.Component {

    state = {
        kardiaRecordings: [],
        kardiaDataLoaded: false,
        kardiaMrn: this.props.location.state.kardia_mrn,
        pdfData: null,
        activeId: null,
        noMoreData: false,
        isProcessing: false,
        pagination: 1,
        patient : JSON.parse(this.props.location.state.patient)
    }




    componentDidMount() {
        this.handleKardiaData();
        this.setState({
            isProcessing: true
        })
    }

    handleActiveData = (e) => {
        this.setState({
            pdfData: e.target.dataset.pdf,
            activeId: e.target.dataset.id
        })
    }



    handleKardiaData = () => {
        const { kardiaMrn, pagination, kardiaRecordings } = this.state;

        axios.get(KARDIA_ECG({ kardiaMrn, pagination }))
            .then((res) => {
                const data = res.data.recordings;
                this.setState({
                    kardiaRecordings: [...kardiaRecordings, ...data],
                    noMoreData: res.data.pagination.links.next === "" ? true : false,
                    activeId: data[0]?.id,
                    pdfData: data[0]?.kardia_pdf_path,
                    isProcessing: false,
                    kardiaDataLoaded: true
                }, () => {
                    const infitr = document.querySelector(".cp_scroll_loader");
                    if (infitr !== null)
                        infitr.addEventListener("scroll", this.handleScroll, true);
                })
            })
    }


    handleScroll = () => {
        const lastLi = document.querySelector(
            ".cp_scroll_loader > div > div:last-child"
        );
        const scrollWin = document.querySelector(".cp_scroll_loader");
        if (lastLi === null) return;
        let lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        let pageOffset = scrollWin.scrollTop + scrollWin.clientHeight + lastLi.clientHeight;
        if (pageOffset > lastLiOffset) {
            this.LoadMore();
        }
    };

    LoadMore = () => {
        const { noMoreData, isProcessing } = this.state;
        if (noMoreData) return;
        if (isProcessing === true) return;
        this.setState(prevState => ({
            isProcessing: true,
            pagination: prevState.pagination + 1
        }), () => this.handleKardiaData())
    }


    render() {
        const { kardiaDataLoaded, pdfData, kardiaRecordings, activeId, isProcessing, subHeaderData } = this.state
        console.log(this.state.patient)
        return (
            <div>
                <HeaderComponent />
                <SubHeaderComponent
                    pageTitle={`${this.state.patient.name} ${this.state.patient.dob !== ""
                        ? this.state.patient.dob + " Yrs,"
                        : ""
                        } ${this.state.patient.gender}`}
                    subPageTitle={UHID(this.state.patient.uhid) !== "" ? 'UHID ' + this.state.patient.uhid : ''}
                    subPageTitle2={'Appointment Time: ' + BOOKINGTIME24TO12(this.state.patient.booking_time)}
                    subPageTitle3={'M : ' + (this.state.patient.number)}
                    PrescriptionBtn={"yes"}
                    E_PrescriptionBtn={LS_SERVICE.has('hospitals') && LS_SERVICE.get('hospitals')[0].id === 1 ? "yes" : "no"}
                    PL={this.props.location.state.PL}
                    btn_name={"Past VC Prescriptions"}
                    patient_id={this.state.patient.id}
                    patient={JSON.stringify(this.state.patient)}
                    booking_id={this.props.location.state.booking_id}
                    booking_code={this.props.location.state.booking_code}
                    allergies_btn={"yes"}
                    ecg_btn={"yes"}
                    backToPatient_btn={"yes"} />
                <div className="container-fluid custom-container">
                    <div>
                        <div className="row">
                            <div className="col-12 p-0">
                                <h5 className="py-3 mb-0">ECG Readings</h5>
                            </div>
                        </div>

                        {kardiaDataLoaded && kardiaRecordings.length > 0 ? (
                            <div className="row ecg__body p-2">
                                <div className="col-3 p-0">
                                    <aside className="aside-copy  w-100 cp_scroll_loader">
                                        <div>
                                            {/* {copyPrescription_data.map((ele, index) => ( */}
                                            {kardiaRecordings.map((record, i) => (
                                                <div key={record.id} className={`border-bottom px-3 pt-1 pb-3 aside-data ${+activeId === +record.id ? 'active' : ''} `} data-id={record.id} data-pdf={record.kardia_pdf_path} onClick={(e) => this.handleActiveData(e)} >
                                                    <div className="ecg-card-bpm d-flex my-2 justify-content-between">
                                                        <div className="ecg-bpm-value d-flex flex-row">
                                                            <img className="mb-1" src={heart} alt="heart-img" />
                                                            <h4 className="pl-2  bpm-sub">{record.bpm}<sub>bpm</sub></h4>
                                                            {/* <p className="mb-0"></p> */}
                                                        </div>
                                                        <div className="font-weight-bold text-capitalize">{record.algorithm_determination}</div>
                                                    </div>
                                                    <p>
                                                        <Moment format="h:mm A, D MMM, Y" parse="YYYY-MM-DD HH:mm">
                                                            {record.recorded_at}
                                                        </Moment>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col justify-content-center align-self-center"
                                            style={{
                                                display: `${isProcessing ? "flex" : "none"}`,
                                                margin: `${isProcessing ? "30px 0" : ""}`,
                                            }}
                                        >
                                            {LOADER_RED}
                                        </div>
                                    </aside>
                                </div>
                                <div className="col-9 pl-2 pr-0">
                                    <div className="ecg_right bg-white">
                                        <object title="Document" data={pdfData} type='application/pdf' data-rotate="0"></object>
                                    </div>
                                </div>
                            </div>
                        ) : kardiaDataLoaded && kardiaRecordings.length === 0 ? (
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <Card className="mb-3">
                                            <Card.Body className="card-bodycustom card-pastVisit">
                                                <Row className="d-flex" style={{ flexWrap: "nowrap" }}>
                                                    <Col className="d-flex justify-content-center">
                                                        <Card.Title className="healtvc-card-title text-dark font-weight-bold mb-0">
                                                            No ECG Readings
                                                            </Card.Title>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        ) : (
                                    <div className="col justify-content-center align-self-center"
                                        style={{
                                            display: `${!kardiaDataLoaded ? "flex" : "none"}`,
                                            margin: `${!kardiaDataLoaded ? "30px 0" : ""}`,
                                        }}
                                    >
                                        {LOADER_RED}
                                    </div>

                                )}
                    </div>


                </div>

            </div>
        )
    }

}


export default withRouter(EcgVc);

